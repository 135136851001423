import React from "react";
import cn from "classnames";

import { RichText } from "@/components/atoms";

import {
  h2Text,
  h6Text,
  textHeadlineNeutral,
  textHeadlineInverse,
  textBodyInverse,
  textBodyNeutral,
} from "@/constants/standardCSSClasses";
import { gql } from "@apollo/client";
import getEditorBlock from "@/fragments/fragmentFunctions/GetEditorBlock";

interface Props {
  includeSubHeading?: boolean;
  content?: string;
  variant?: string;
  attributionName?: string;
  attributionPosition?: string;
}

export default function StatisticElementBlock({
  includeSubHeading = false,
  content = "",
  variant = "dark",
  attributionName = "",
  attributionPosition = "",
}: Props) {
  const headerClassNames = cn("text-center mb-0 mt-0", h2Text, {
    [textHeadlineInverse]: variant === "dark",
    [textHeadlineNeutral]: variant !== "dark",
    "mb-2": includeSubHeading === true,
    "mb-0": includeSubHeading === false,
  });

  const subHeaderClassNames = cn("text-center mt-0 mb-0", h6Text, {
    [textHeadlineInverse]: variant === "dark",
    [textHeadlineNeutral]: variant !== "dark",
  });

  const pTextClassNames = cn("text-base leading-6", {
    [textBodyInverse]: variant === "dark",
    [textBodyNeutral]: variant !== "dark",
  });

  const cardsWrapper = cn(
    "p-8 md:p-10 gap-4 rounded flex flex-col justify-stretch h-full text-center border border-grey-1 border-solid"
  );

  return (
    <div className={cardsWrapper}>
      <div>
        <RichText className={headerClassNames} tag="h2">
          {attributionName}
        </RichText>
        {includeSubHeading && (
          <RichText className={subHeaderClassNames} tag="h6">
            {attributionPosition}
          </RichText>
        )}
      </div>
      <div className="flex-1">
        <RichText className={pTextClassNames} tag="p">
          {content}
        </RichText>
      </div>
    </div>
  );
}

// Must match __typename
const BLOCK_NAME = "TenupStatisticElement";

StatisticElementBlock.displayName = BLOCK_NAME;

StatisticElementBlock.fragments = {
  key: `${BLOCK_NAME}BlockFragment`,
  entry: gql`
    fragment ${BLOCK_NAME}BlockFragment on ${BLOCK_NAME} {
      ${getEditorBlock()}
      attributes {
        ... on ${BLOCK_NAME}Attributes {
          includeSubHeading
          attributionName
          attributionPosition
          className
          content
          metadata
        }
      }
    }
  `,
};
