export const RESOURCE_BACK_TO_HUB_BUTTON_V2 = {
  "en": "Back to hub",
  "fr": "Retour au hub",
  "de": "Zurück zum Hub",
  "es": "Volver al centro",
  "pt-br": "Back to hub",
  "it": "Back to hub",
  "ja": "Back to hub",
  "ko": "Back to hub",
  "nl": "Back to hub",
  "pt-pt": "Back to hub",
  "sv": "Back to hub",
  "zh": "Back to hub"
}