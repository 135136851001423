import React, { useEffect, useState } from "react";
import RequestImage from "./talk-to-sales/request.png";
import MailIcon from "./talk-to-sales/mail.svg";
import PhoneIcon from "./talk-to-sales/phone.svg";
import CalendarIcon from "./talk-to-sales/calendar.svg";
import DashlaneLogo from "./talk-to-sales/dashlane-logo.svg";
import Image from "next/image";
import { cn } from "@nextui-org/theme";
import SalesEmailCaptureForm from "@/components/molecules/SalesEmailCaptureForm";
import { loadBookItIframe } from "@/utilities/loadLeanData";
import {
  T2S_BUTTON_TEXT,
  T2S_CALL_US,
  T2S_EMAIL_US,
  T2S_SELECT_DATE,
  T2S_SELECT_DATE_DESCRIPTION,
  T2S_SUBTITLE,
  T2S_TITLE,
  T2S_WHAT_HAPPENS_NEXT,
} from "@/i18n/translations";
import { useTranslate } from "@/hooks/useTranslate";

const TalkToSales: React.FC = () => {
  const [showCalendar, setShowCalendar] = useState(false);
  const [email, setEmail] = useState("");
  const { translate } = useTranslate();

  const bgStyles = cn(
    "flex justify-center items-center bg-gray-100 min-h-[100vh]"
  );
  const containerStyles = cn("flex bg-white w-[800px] h-[550px] py-6");

  const leftColStyles = cn("px-6 border-r border-gray-300 min-w-[335px]");
  const logoStyles = cn("m-w-[158px]");

  const rightColStyles = cn(
    "flex flex-col justify-center items-center w-full px-6"
  );
  const nextContainerStyles = cn(
    "icon-container flex w-full max-w-md flex-col justify-around gap-4"
  );
  const nextItemStyles = cn("icon-item flex items-center");
  const nextIconStyles = cn("h-8 w-8 mr-2");

  useEffect(() => {
    if (showCalendar) {
      loadBookItIframe("Talk To Sales CTA");
    }
  }, [showCalendar]);

  useEffect(() => {
    const handleBookItResponse = (e: MessageEvent) => {
      if (e.data.message === "LD_ROUTING_RESPONSE") {
        const routingResponseData = e.data.responseData;
        const calendarLink = routingResponseData?.calendarLink;

        if (!calendarLink) {
          setShowCalendar(false);
        }

        window.removeEventListener("message", handleBookItResponse);
      }
    };

    if (window.LDBookItV2) {
      window.addEventListener("message", handleBookItResponse);
    }

    return () => {
      window.removeEventListener("message", handleBookItResponse);
    };
  }, []);

  useEffect(() => {
    const possibleOrigins = [
      "https://app.dashlane.com",
      "chrome-extension://",
      "extension://",
      "moz-extension://",
      "safari-web-extension://",
    ];

    const handleEmailCapture = (e: MessageEvent) => {
      if (possibleOrigins.some((origin) => e.origin.includes(origin))) {
        const email = e.data.email;
        setEmail(email);
        window.removeEventListener("message", handleEmailCapture);
      }
    };

    window.addEventListener("message", handleEmailCapture);

    return () => {
      window.removeEventListener("message", handleEmailCapture);
    };
  }, []);

  return (
    <div className={bgStyles}>
      {!showCalendar ? (
        <div className={containerStyles}>
          <>
            <div className={leftColStyles}>
              <Image
                src={DashlaneLogo}
                alt="Dashlane Logo"
                className={logoStyles}
              />
              <p className="mt-6 text-xs uppercase">
                {translate(T2S_SUBTITLE)}
              </p>
              <h1 className="mb-4 text-3xl font-medium text-[#212529]">
                {translate(T2S_TITLE)}
              </h1>
              <Image
                src={RequestImage}
                alt="Request"
                className="mb-6 w-full max-w-md"
              />
              <div className={nextContainerStyles}>
                <p className="text-xs uppercase">
                  {translate(T2S_WHAT_HAPPENS_NEXT)}
                </p>
                <div className={nextItemStyles}>
                  <Image
                    src={MailIcon}
                    alt="Mail Icon"
                    className={nextIconStyles}
                  />
                  <span className="mt-2 text-sm">
                    {translate(T2S_EMAIL_US)}
                  </span>
                </div>
                <div className={nextItemStyles}>
                  <Image
                    src={CalendarIcon}
                    alt="Calendar Icon"
                    className={nextIconStyles}
                  />
                  <div>
                    <span className="mt-2 text-sm">
                      {translate(T2S_SELECT_DATE)}
                    </span>
                    <p className="text-xs">
                      {translate(T2S_SELECT_DATE_DESCRIPTION)}
                    </p>
                  </div>
                </div>
                <div className={nextItemStyles}>
                  <Image
                    src={PhoneIcon}
                    alt="Phone Icon"
                    className={nextIconStyles}
                  />
                  <span className="mt-2 text-sm">{translate(T2S_CALL_US)}</span>
                </div>
              </div>
              <div className="mb-4 text-center"></div>
            </div>
            <div className={rightColStyles}>
              <div className="w-full">
                <SalesEmailCaptureForm
                  setShowCalendar={setShowCalendar}
                  buttonText={translate(T2S_BUTTON_TEXT)}
                  email={email}
                />
              </div>
            </div>
          </>
        </div>
      ) : (
        <div className="bookit-content-container w-full"></div>
      )}
    </div>
  );
};

export default TalkToSales;
