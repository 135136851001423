export const T2S_YOUR_EMAIL = {
  "en": "Your email",
  "fr": "Votre adresse email",
  "de": "Ihre E-Mail-Adresse",
  "es": "Su correo electrónico",
  "pt-br": "Your email",
  "it": "Your email",
  "ja": "Your email",
  "ko": "Your email",
  "nl": "Your email",
  "pt-pt": "Your email",
  "sv": "Your email",
  "zh": "Your email"
}