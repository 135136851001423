export const T2S_BUTTON_TEXT = {
  "en": "Confirm and continue",
  "fr": "Confirmer et continuer",
  "de": "Bestätigen und fortfahren",
  "es": "Confirmar y continuar",
  "pt-br": "Confirm and continue",
  "it": "Confirm and continue",
  "ja": "Confirm and continue",
  "ko": "Confirm and continue",
  "nl": "Confirm and continue",
  "pt-pt": "Confirm and continue",
  "sv": "Confirm and continue",
  "zh": "Confirm and continue"
}