import EmailCaptureForm from "@/components/molecules/EmailCaptureForm";
import { BLOG_SIGN_UP_TITLE } from "@/i18n/translations";
import { useTranslate } from "@/hooks/useTranslate";
import { useState } from "react";

interface Props {
  heading?: string;
  content?: string;
  buttonText?: string;
  ga4Id?: string;
  ga4SectionId?: string;
}

export default function SignUpFormRedesigned({
  heading,
  content,
  buttonText,
  ga4Id,
  ga4SectionId,
}: Props) {
  const { translate } = useTranslate();
  const [isSubmitted, setIsSubmitted] = useState(false);

  const formHeading = heading ? heading : translate(BLOG_SIGN_UP_TITLE);
  const formContent = content ? content : "";
  const formButtonText = buttonText ? buttonText : "";

  return (
    <div
      className="mt-8 rounded bg-blue-8 px-6 py-10"
      {...(ga4SectionId ? { "data-component": ga4SectionId } : {})}
    >
      {!isSubmitted && (
        <>
          <p className="mb-4 text-2xl text-white">{formHeading}</p>
          {content && (
            <p className="mb-4 text-base text-grey-3">{formContent}</p>
          )}
        </>
      )}
      <EmailCaptureForm
        variant="dark"
        setIsSubmitted={setIsSubmitted}
        buttonText={formButtonText}
        column
        ga4Id={ga4Id}
      />
    </div>
  );
}
