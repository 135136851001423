import React, { useEffect } from "react";

import CloseIcon from "../icons/CloseIcon";
import getCookieDomain from "@/utilities/getCookieDomain";
import Cookies from "js-cookie";
import CustomButtonGroup from "../molecules/CustomButtonGroup";
import { useTranslate } from "@/hooks/useTranslate";
import {
  PRIVACY_CONSENT_ACCEPT_REDESIGN,
  PRIVACY_CONSENT_REFUSE_REDESIGN,
  PRIVACY_CONSENT_TITLE_CTA_REDESIGN,
  PRIVACY_CONSENT_TITLE_REDESIGN,
} from "@/i18n/translations";
import { I18nElement } from "@/i18n/i18nElement";

export interface CookieBannerProps {
  setShowCookieBanner: any;
  setLoadTrackers: any;
  isVisible?: boolean;
  isImplicitConsent?: boolean;
}

export default function CookieBanner({
  isVisible = false,
  setShowCookieBanner,
  setLoadTrackers,
  isImplicitConsent = false,
}: CookieBannerProps) {
  const { translate, locale } = useTranslate();

  const buttons = [
    {
      variant: "dark",
      text: translate(PRIVACY_CONSENT_ACCEPT_REDESIGN),
      size: "sm",
      className: "block",
      onClick: () => onAccept(setShowCookieBanner, setLoadTrackers),
    },
    {
      variant: "light",
      text: translate(PRIVACY_CONSENT_REFUSE_REDESIGN),
      size: "sm",
      className: "block",
      onClick: () => onRefuse(setShowCookieBanner),
    },
  ];

  useEffect(() => {
    if (isImplicitConsent) {
      onAccept(setShowCookieBanner, setLoadTrackers, isImplicitConsent);
    }
  }, [isImplicitConsent]);

  return (
    <div
      className={`fixed bottom-0 z-20 w-full bg-blue-light md:bottom-10 md:left-auto md:right-10 md:max-w-[516px] md:rounded-2xl md:shadow-md ${
        !isVisible && "hidden"
      }`}
    >
      <div className="banner-wrap ml-auto mr-auto flex max-w-6xl flex-col items-start gap-5 p-6 md:p-8">
        <div className="banner-copy" aria-hidden={!isVisible}>
          <p className="text-left text-xs md:text-sm">
            <span>
              <I18nElement
                i18nKey={PRIVACY_CONSENT_TITLE_REDESIGN}
                locale={locale}
              />
            </span>
          </p>
        </div>
        <div className="banner-cta-container flex w-full flex-col justify-between gap-4 md:w-auto md:flex-row">
          <CustomButtonGroup align="left" buttons={buttons} marginTop="" />
          <button
            className="absolute right-[12px] top-[12px] cursor-pointer md:my-auto"
            onClick={() => setShowCookieBanner(false)}
          >
            <CloseIcon />
          </button>
        </div>
        <a
          href={
            locale !== "en"
              ? `https://www.dashlane.com/${locale}/privacy/cookiepreferences`
              : "https://www.dashlane.com/privacy/cookiepreferences"
          }
          className="w-full text-center text-sm text-grey-6 underline md:text-left"
        >
          <I18nElement
            i18nKey={PRIVACY_CONSENT_TITLE_CTA_REDESIGN}
            locale={locale}
          />
        </a>
      </div>
    </div>
  );
}

function onAccept(
  setShowCookieBanner: any,
  setLoadTrackers: any,
  isImplicitConsent: boolean = false
) {
  Cookies.set(
    "userPrivacyCookie",
    JSON.stringify({
      analytics: { enabled: true, date: +new Date() },
      marketing: { enabled: true, date: +new Date() },
    }),
    {
      expires: 365,
      path: "/",
      secure: true,
      sameSite: "Strict",
      domain: getCookieDomain(window.location.hostname),
    }
  );
  setLoadTrackers({
    analytics: true,
    marketing: true,
  });

  !isImplicitConsent && setShowCookieBanner(false);
}

function onRefuse(setShowCookieBanner: any) {
  Cookies.set(
    "userPrivacyCookie",
    JSON.stringify({
      analytics: { enabled: false, date: +new Date() },
      marketing: { enabled: false, date: +new Date() },
    }),
    {
      expires: 365,
      path: "/",
      secure: true,
      sameSite: "Strict",
      domain: getCookieDomain(window.location.hostname),
    }
  );
  setShowCookieBanner(false);
}
