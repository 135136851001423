import React from "react";
import classnames from "classnames";
import dynamic from "next/dynamic";
const Player = dynamic(
  () => import("@lottiefiles/react-lottie-player").then((mod) => mod.Player),
  {
    ssr: false,
  }
);

export interface LottieInterface {
  className?: string;
  url: string | object;
  title?: string;
  caption?: string;
  aspect?: string;
}

export default function LottieEmbed({
  className,
  url,
  aspect,
}: LottieInterface) {
  const classNames = classnames(className, "relative", {
    "aspect-[1/1]": aspect === "1:1",
    "aspect-[3/2]": aspect === "3:2",
    "aspect-[2/1]": aspect === "2:1",
    "aspect-[824/477]": aspect === "824:477" || aspect === "contentCard",
  });

  const figureClassNames = classnames({
    "w-full h-full object-cover flex items-center justify-center": aspect,
  });

  return (
    <div className={classNames}>
      <figure className={figureClassNames}>
        <Player autoplay loop src={url} />
      </figure>
    </div>
  );
}
