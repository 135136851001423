export const T2S_CONFIRM_EMAIL = {
  "en": "Confirm your email to book a call with us securely",
  "fr": "Confirmez votre e-mail pour réserver un appel avec nous en toute sécurité",
  "de": "Bestätigen Sie Ihre E-Mail, um einen Anruf fest bei uns zu buchen",
  "es": "Confirme su correo electrónico para reservar una llamada con nosotros de forma segura",
  "pt-br": "Confirm your email to book a call with us securely",
  "it": "Confirm your email to book a call with us securely",
  "ja": "Confirm your email to book a call with us securely",
  "ko": "Confirm your email to book a call with us securely",
  "nl": "Confirm your email to book a call with us securely",
  "pt-pt": "Confirm your email to book a call with us securely",
  "sv": "Confirm your email to book a call with us securely",
  "zh": "Confirm your email to book a call with us securely"
}