export function observeHeadings(callback: (id: string) => void) {
  const options = {
    root: null,
    threshold: 0.6,
    rootMargin: "0px 0px -60% 0px",
  };

  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        callback(entry.target.id);
      }
    });
  }, options);

  const headings = document.querySelectorAll("h2[id]:not(li h2)");
  headings.forEach((heading) => observer.observe(heading));
}
