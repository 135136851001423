import React from "react";
import { useState } from "react";
import cn from "classnames";
import BlockContent from "../molecules/BlockContent";
import { RichText } from "@/components/atoms";
import { useWindowSize } from "@/hooks/useWindowSize";
import Icon from "@/components/molecules/Icon";
import { iconClose } from "@/constants/icons";
import { MD_BREAK } from "@/constants/utility";
import { CustomButtonInterface } from "@/components/molecules/CustomButton";
import CustomButtonGroup from "@/components/molecules/CustomButtonGroup";

export interface SecondaryBannerProps {
  includeButtons?: boolean;
  includeCloseButton?: boolean;
  includeDate?: boolean;
  moveBannerToTop?: boolean;
  content?: string;
  variant?: string;
  width?: string;
  displayDate?: string;
  expireDate?: string;
  button?: CustomButtonInterface;
  contentType?: string;
}

export default function SecondaryBanner({
  includeButtons = true,
  includeCloseButton = true,
  includeDate = true,
  content = "",
  variant = "orange",
  button,
  width = "full",
  displayDate = "",
  expireDate = "",
  contentType,
}: SecondaryBannerProps) {
  const [showBanner, setShowBanner] = useState(true);
  const { width: windowWidth } = useWindowSize();
  const isMobile = windowWidth < MD_BREAK;

  if (!showBanner) return null;

  if (includeDate) {
    const today = new Date();
    const formattedDisplayDate = new Date(displayDate);
    const formattedExpireDate = new Date(expireDate);

    if (expireDate) {
      if (today > formattedExpireDate) {
        return null;
      }
    }

    if (displayDate) {
      if (today < formattedDisplayDate) {
        return null;
      }
    }
  }

  const dismiss = () => {
    setShowBanner(false);
  };

  const wrapperClassNames = cn("relative", {
    "bg-[#FFC895]": variant === "orange",
    "bg-[#9BD8DE]": variant === "blue",
  });

  const buttons = [
    {
      ...button,
      text: button?.text,
      variant: variant,
      textOnly: true,
      darkerText: true,
      chevron: true,
      size: isMobile ? "sm" : "lg",
    },
  ];

  const pTextClassNames = cn(
    "text-blue-8 font-medium m-0 p-0 inline text-base md:text-lg"
  );

  const buttonClassNames = cn(
    "text-blue-8 font-medium m-0 p-0 underline text-center mt-0 mb-0 p-0 !inline-block text-base md:text-lg ms-1"
  );

  return (
    <BlockContent
      className={wrapperClassNames}
      contentType={contentType}
      width={width}
      padding="px-5 py-2.5 md:px-0"
    >
      <div className="w-100 md:flex md:items-center md:justify-center">
        <div className="w-100 text-left md:flex md:flex-row md:flex-wrap md:items-center md:justify-center md:gap-2 md:text-center">
          <div className="inline md:flex md:items-center md:gap-1">
            <RichText className={pTextClassNames} tag="p">
              {content}
            </RichText>
            {includeButtons && (
              <CustomButtonGroup
                align="wide"
                buttons={buttons}
                marginTop=""
                mobileWide={false}
                className={buttonClassNames}
              />
            )}
          </div>
        </div>
      </div>
      {includeCloseButton && (
        <div className="absolute right-5 top-5 md:right-0 md:top-[50%] md:translate-x-[-50%] md:translate-y-[-50%]">
          <Icon
            source={iconClose}
            variant={variant}
            className="ms-6 flex cursor-pointer items-center"
            width={16}
            as="button"
            onClick={dismiss}
            ariaLabel="Close"
            name="close"
          />
        </div>
      )}
    </BlockContent>
  );
}
