import cn from "classnames";
import { CustomButtonInterface } from "../molecules/CustomButton";
import { CardInterface } from "@/components/molecules/Card";
import React from "react";
import ContentCards from "@/components/molecules/ContentCards";
import { Block } from "@/types";
import { HeadingOptions } from "@/components/molecules/CustomHeading";
import { gql } from "@apollo/client";
import getEditorBlock from "@/fragments/fragmentFunctions/GetEditorBlock";

interface Props {
  includeAboveHeading?: boolean;
  includeButtons?: boolean;
  includeContent?: boolean;
  includeHeading?: boolean;
  includeCardButtons?: boolean;
  includeCardImages?: boolean;
  includeTag?: boolean;
  aboveHeading?: string;
  heading?: string;
  headingOptions?: HeadingOptions;
  content?: string;
  variant?: string;
  buttonsNumber?: number;
  button?: CustomButtonInterface;
  contentType: string;
  backgroundStyle?: "full" | "boxed";
  innerBlocks?: Block[];
  paddingTop?: string;
  paddingBottom?: string;
}

export default function ContentCardsManualBlock({
  includeAboveHeading = true,
  includeButtons = true,
  includeHeading = true,
  includeContent = true,
  includeCardButtons = false,
  includeCardImages = true,
  includeTag = true,
  aboveHeading,
  heading,
  headingOptions = {
    tag: 2,
  },
  content,
  variant = "dark",
  button,
  contentType,
  backgroundStyle = "full",
  innerBlocks,
  paddingTop,
  paddingBottom,
}: Props) {
  const cards: Array<CardInterface> | undefined = innerBlocks?.map(
    ({ attributes }) => {
      const {
        heading: cardHeading = "",
        content: cardContent = "",
        button: cardButton = {},
        tag: cardTag = "",
        image = { full: "", alt: "" },
        link = { url: "" },
        media = { type: "image", media: {} },
      } = attributes;

      return {
        variant,
        imageSize: "md",
        visual: "image",
        border: false,
        heading: cardHeading,
        content: cardContent,
        image,
        media,
        tag: includeTag ? { name: cardTag } : undefined,
        includeCardButtons,
        includeCardImages,
        button: {
          ...cardButton,
          className: cn({
            "mb-[-8px]": backgroundStyle === "boxed",
            "mt-6": backgroundStyle === "boxed" && innerBlocks?.length === 1,
          }),
          size: innerBlocks?.length === 1 ? "lg" : "md",
          variant,
          mobileWide: false,
          align: "left",
        },
        linkUri: link.url,
        linkCard: true,
        backgroundStyle,
        headingClassName: backgroundStyle === "boxed" ? "mb-4" : "",
      };
    }
  );

  return (
    <ContentCards
      includeAboveHeading={includeAboveHeading}
      includeButtons={includeButtons}
      includeContent={includeContent}
      includeHeading={includeHeading}
      aboveHeading={aboveHeading}
      heading={heading}
      headingOptions={headingOptions}
      content={content}
      variant={variant}
      button={button}
      contentType={contentType}
      backgroundStyle={backgroundStyle}
      cards={cards}
      columns={cards?.length === 1 ? 1 : cards?.length === 2 ? 2 : 3}
      featureSingle={cards?.length === 1}
      featureSize="sm"
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
    />
  );
}

const BLOCK_NAME = "TenupContentCardsManual";

ContentCardsManualBlock.displayName = BLOCK_NAME;

ContentCardsManualBlock.fragments = {
  key: `${BLOCK_NAME}BlockFragment`,
  entry: gql`
    fragment ${BLOCK_NAME}BlockFragment on ${BLOCK_NAME} {
      ${getEditorBlock()}
      attributes {
        ... on ${BLOCK_NAME}Attributes {
          aboveHeading
          backgroundStyle
          button
          className
          content
          ga4SectionId
          heading
          headingOptions
          includeAboveHeading
          includeButtons
          includeCardButtons
          includeCardImages
          includeContent
          includeHeading
          includeTag
          metadata
          variant
          paddingTop
          paddingBottom
        }
      }
    }
  `,
};
