import React from "react";
import { gql } from "@apollo/client";
import * as MENUS from "@/constants/menus";
import { ResourcesCardFragment } from "@/fragments";
import { ArchiveTemplate } from "@/components/organisms";
import type {
  Locale,
  OffsetPagination,
  RelatedResource,
  SeedNode,
} from "@/types";
import { PAGE_SIZE } from "@/constants/utility";
import { formatLocaleForGraphQL } from "@/utilities/formatLocaleForGraphQL";
import { GetArchive } from "@/fragments/fragmentFunctions";
import { formatEventDate } from "@/utilities/formatEventDate";
import ArchiveBackButton from "@/components/atoms/ArchiveBackButton";
import { CardInterface } from "@/components/molecules/Card";
import ContentCards from "@/components/molecules/ContentCards";
import cn from "classnames";
import { h3Text, innerContainer } from "@/constants/standardCSSClasses";
import { mapResourcesToCards } from "@/utilities/mapResourcesToCards";
import { BlockAttributesJson } from "@/types";
import { Blocks } from "@/components/molecules";
import { Site } from "@/types";
import {
  RESOURCE_ARCHIVE_ALL_HEADING,
  RESOURCE_ARCHIVE_FEATURED_HEADING,
  RESOURCE_BACK_TO_HUB_BUTTON,
  RESOURCE_BACK_TO_PARENT_CATEGORY,
} from "@/i18n/translations";
import { useTranslate } from "@/hooks/useTranslate";
import { useBrowserLanguage } from "@/hooks/useBrowserLanguage";
import { SearchForm } from "@/components/atoms";
import flatListToHierarchical from "@/utilities/flatListToHierarchical";

interface Props extends Site {
  data: Site["data"] & {
    resourceCategory: {
      name: string;
      description: string;
      parent?: {
        node?: {
          uri?: string;
          name?: string;
        };
      };
    };
    resources: {
      nodes: RelatedResource[];
      pageInfo: {
        offsetPagination: OffsetPagination;
      };
    };
    featured: {
      nodes: RelatedResource[];
    };
    wpBlock: {
      editorBlocks: BlockAttributesJson[];
    };
  };
}

export default function Component(props: Props) {
  const { translate, translateInterpolate } = useTranslate();
  const { locale } = props;
  const { language } = useBrowserLanguage(locale);
  const categoryName = props.__SEED_NODE__.slug;
  const uri = props.__SEED_NODE__.uri;
  const nodes = props.data.resources.nodes;
  const featured = props.data.featured.nodes;
  const name = props.data.resourceCategory.name;
  const categoryParentUri = props.data.resourceCategory.parent?.node?.uri;
  const categoryParentName = props.data.resourceCategory.parent?.node?.name;
  const posts = nodes.map((each) => {
    return {
      ...each,
      date: "",
      eventDate: formatEventDate(each.resourceFields.eventDate, language, true),
      categories: each.resourceTypes ?? { nodes: [] },
      translated: [],
      uri: each.resourceFields.externalUrl
        ? each.resourceFields.externalUrl
        : each.uri,
    };
  });

  const cards: Array<CardInterface> = mapResourcesToCards(
    featured,
    locale,
    "resourceTypes"
  );

  const offsetPagination = props.data.resources.pageInfo.offsetPagination;
  const { page = 1 } = offsetPagination || {};

  return (
    <ArchiveTemplate
      name={name}
      inserts={{
        beforeTitle: (
          <ArchiveBackButton
            text={
              categoryParentName
                ? translateInterpolate(RESOURCE_BACK_TO_PARENT_CATEGORY, {
                    parent: categoryParentName,
                  })
                : translate(RESOURCE_BACK_TO_HUB_BUTTON)
            }
            href={
              categoryParentUri ? categoryParentUri.substring(1) : "resources"
            }
          />
        ),
        afterTitle: (
          <SearchForm
            className="mb-6 max-w-2xl"
            path="/search"
            basePathOverwrite="/resources"
            category={categoryName}
            filters={{ resourceType: true, resourceCategory: true }}
          />
        ),
        featured:
          page <= 1 && cards.length ? (
            <ContentCards
              featureSingle={true}
              includeAboveHeading={false}
              includeButtons={false}
              includeContent={false}
              border={true}
              heading={translate(RESOURCE_ARCHIVE_FEATURED_HEADING)}
              headingOptions={{
                tag: 2,
              }}
              variant="white"
              contentType=""
              loading={false}
              cards={cards}
              columns={cards.length <= 2 ? 2 : 3}
            />
          ) : null,
        beforeArchive: cards.length ? (
          <h2 className={cn(h3Text, "mb-10 mt-20")}>
            {translateInterpolate(RESOURCE_ARCHIVE_ALL_HEADING, {
              category: name,
            })}
          </h2>
        ) : (
          <div className="mb-20" />
        ),
        afterPagination: props.data.wpBlock?.editorBlocks && (
          <div className={innerContainer}>
            <Blocks
              blocks={flatListToHierarchical(props.data.wpBlock?.editorBlocks, {
                idKey: "id",
                parentKey: "parentClientId",
                childrenKey: "innerBlocks",
              })}
            />
          </div>
        ),
      }}
      posts={posts}
      locale={locale}
      categoryName={categoryName}
      metaTitle={props.data.generalSettings.title}
      description={props.data.resourceCategory.description}
      metaDescription={props.data.generalSettings.description}
      primaryMenu={props.data.headerMenuItems.nodes ?? []}
      newMenu={props.data.newMenuItems.nodes ?? []}
      offsetPagination={offsetPagination}
      footerMenu={props.data.footerMenuItems.nodes ?? []}
      hero={true}
      uri={uri}
    />
  );
}

Component.query = GetArchive(
  `
    resourceCategory(id: $categoryName, idType: SLUG) {
      name
      description
      parent {
        node {
          name
          uri
        }
      }
    }
    resources: resources(where: { resourceCategoryName: $categoryName, offsetPagination: { offset: $offset, size: $size } }) {
      ...RootQueryToResourceConnectionFragment
    }
    featured: resources(first: $size, where: { featured: 1, resourceCategoryName: $categoryName }) {
      ...RootQueryToResourceConnectionFragment
    }`,
  gql`
    ${ResourcesCardFragment}
  `
);

Component.variables = (
  { slug }: SeedNode,
  { locale }: { locale: Locale },
  extra: any
) => {
  const { page } = extra;
  return {
    categoryName: slug,
    headerLocation: MENUS.newHeaderLocation(locale),
    footerLocation: MENUS.newFooterLocation(locale),
    newMenuLocation: MENUS.newMenuLocation(locale),
    locale: formatLocaleForGraphQL(locale),
    size: PAGE_SIZE,
    offset: page ? PAGE_SIZE * (page - 1) : 0,
    blockId: process.env["NEXT_PUBLIC_RESOURCE_PATTERN_ID"],
  };
};
