import cn from "classnames";
import { Blocks } from "@/components/molecules";
import { Block } from "@/types";
import { HeadingOptions } from "@/components/molecules/CustomHeading";
import {
  backgroundWhiteDark,
  backgroundNeutralDark,
  backgroundInverseLightAlt,
  borderBoth,
} from "@/constants/standardCSSClasses";
import { gql } from "@apollo/client";
import getEditorBlock from "@/fragments/fragmentFunctions/GetEditorBlock";

interface Props {
  border?: boolean;
  headingOptions?: HeadingOptions;
  variant?: string;
  type: string;
  striped?: boolean;
  headerColumn?: boolean;
  highlightColumn?: number;
  sticky: number;
  columns: number;
  index: number;
  innerBlocks?: Block[];
}

export default function TabularContentRowBlock({
  border = false,
  headingOptions = { tag: 2 },
  variant = "white",
  type = "text",
  striped,
  highlightColumn = 0,
  headerColumn = false,
  sticky,
  columns,
  index,
  innerBlocks,
}: Props) {
  const noBorders = ["spacing", "pricing", "image"];

  const blockClassNames = cn("table-row", {
    [backgroundWhiteDark]:
      striped &&
      !sticky &&
      index % 2 === 1 &&
      variant === "white" &&
      type !== "spacing",
    [backgroundNeutralDark]:
      striped &&
      !sticky &&
      index % 2 === 1 &&
      variant === "light" &&
      type !== "spacing",
    [backgroundInverseLightAlt]:
      striped &&
      !sticky &&
      index % 2 === 1 &&
      variant === "dark" &&
      type !== "spacing",
    "h-[56px]": type === "spacing",
    [borderBoth]: border && !noBorders.includes(type),
    "border border-t-0 border-l-0 border-r-0 border-solid":
      border && !noBorders.includes(type),
    "border-none": sticky && index === 0 && type === "text",
  });

  const cells =
    innerBlocks?.length && type !== "spacing" ? (
      <Blocks
        blocks={innerBlocks.map((each, columnIndex) => {
          each.attributes.variant = variant;
          each.attributes.highlightColumn = highlightColumn;
          each.attributes.headingOptions = headingOptions;
          each.attributes.index = columnIndex;
          each.attributes.sticky = sticky;
          each.attributes.columns = columns;
          each.attributes.headerColumn = headerColumn;
          each.attributes.striped = striped && index % 2 === 1 && !sticky;
          each.attributes.rowType = type;

          return each;
        })}
      />
    ) : (
      <td colSpan={columns} />
    );

  return <tr className={blockClassNames}>{cells}</tr>;
}

// Must match __typename
const BLOCK_NAME = "TenupTabularContentRow";

TabularContentRowBlock.displayName = BLOCK_NAME;

TabularContentRowBlock.fragments = {
  key: `${BLOCK_NAME}BlockFragment`,
  entry: gql`
    fragment ${BLOCK_NAME}BlockFragment on ${BLOCK_NAME} {
      ${getEditorBlock()}
      attributes {
        ... on ${BLOCK_NAME}Attributes {
          className
          metadata
          type
        }
      }
    }
  `,
};
