import React from "react";
import { gql } from "@apollo/client";
import { AttributesExtension } from "@/utilities/getBlockContentProps";
import getEditorBlock from "@/fragments/fragmentFunctions/GetEditorBlock";
import { CustomButtonInterface } from "@/components/molecules/CustomButton";
import SecondaryBanner from "../molecules/SecondaryBanner";

interface Props extends AttributesExtension {
  includeButtons?: boolean;
  includeCloseButton?: boolean;
  includeDate?: boolean;
  moveBannerToTop?: boolean;
  content?: string;
  variant?: string;
  width?: string;
  displayDate?: string;
  expireDate?: string;
  button?: CustomButtonInterface;
  contentType: string;
}

export default function SecondaryBannerBlock({
  includeButtons = true,
  includeCloseButton = true,
  includeDate = true,
  moveBannerToTop = false,
  content = "",
  variant = "orange",
  button,
  width = "full",
  displayDate = "",
  expireDate = "",
  contentType,
  blockContentProps,
}: Props) {
  const attributes = {
    includeButtons,
    includeCloseButton,
    includeDate,
    content,
    variant,
    button,
    width,
    displayDate,
    expireDate,
    contentType,
    ...blockContentProps,
  };

  if (moveBannerToTop === true) {
    return null;
  }

  return <SecondaryBanner {...attributes} />;
}

// Must match __typename
const BLOCK_NAME = "TenupSecondaryBanner";

SecondaryBannerBlock.displayName = BLOCK_NAME;

SecondaryBannerBlock.fragments = {
  key: `${BLOCK_NAME}BlockFragment`,
  entry: gql`
    fragment ${BLOCK_NAME}BlockFragment on ${BLOCK_NAME} {
      ${getEditorBlock()}
      attributes {
        ... on ${BLOCK_NAME}Attributes {
          width
          content
          className
          includeButtons
          includeCloseButton
          includeDate
          moveBannerToTop
          displayDate
          expireDate
          metadata
          variant
          button
          ga4SectionId
        }
      }
    }
  `,
};
