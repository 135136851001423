import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { rightArrow, caretRight } from "@/constants/icons";
import { useRouter } from "next/router";
import affiliateSetup from "@/utilities/affiliateSetup";
import Link from "next/link";

export interface CustomButtonInterface {
  text?: string;
  variant?: string;
  href?: string;
  onClick?(): any;
  size?: string;
  target?: string;
  rel?: string;
  align?: string;
  mobileWide?: boolean;
  className?: string | null;
  textOnly?: boolean;
  darkerText?: boolean;
  arrow?: boolean;
  chevron?: boolean;
  wrap?: boolean;
  disabled?: boolean;
  iosAdjust?: string;
  androidAdjust?: string;
  ga4PlanSegment?: string;
  ga4Id?: string;
  downloadAndOpenTab?: boolean;
}

const CustomButton = ({
  text,
  variant,
  href,
  target = "",
  rel = "",
  size = "lg",
  className = "",
  mobileWide = true,
  onClick,
  align = "left",
  textOnly = false,
  darkerText = false,
  arrow = false,
  chevron = false,
  wrap = true,
  disabled = false,
  iosAdjust,
  androidAdjust,
  ga4PlanSegment,
  ga4Id,
  downloadAndOpenTab = false,
}: CustomButtonInterface) => {
  const router = useRouter();
  const [ctaHref, setCtaHref] = useState(href);

  useEffect(() => {
    if (!router.isReady) return;

    const {
      utm_campaign = "",
      utm_content = "",
      utm_term = "",
      utm_medium = "",
    } = router.query;

    if (iosAdjust || androidAdjust) {
      const adjustUrl = affiliateSetup.getAdjustUrl(
        iosAdjust,
        androidAdjust,
        utm_campaign as string,
        utm_content as string,
        utm_term as string,
        utm_medium as string
      );

      if (adjustUrl) {
        setCtaHref(adjustUrl);
      }
    }
  }, [router.isReady]);

  if (!text) return null;

  const classNames = classnames(
    className,
    "rounded-md cursor-pointer hover:no-underline transition-colors duration-500 min-w-fit flex items-center",
    {
      "text-lg px-5 py-3": size === "lg" && !textOnly,
      "px-4 py-3": size === "md" && !textOnly,
      "px-3.5 py-2.5 text-sm": size === "sm" && !textOnly,
      "text-lg py-2":
        size === "lg" && textOnly && variant !== "orange" && variant !== "blue",
      "py-1.5": size === "md" && textOnly,
      "py-1 text-sm":
        size === "sm" && textOnly && variant !== "orange" && variant !== "blue",

      "bg-blue-6 hover:bg-blue-7 active:bg-blue-8 disabled:opacity-10 text-white fill-white":
        variant === "dark" && !textOnly,
      "bg-blue-2 hover:bg-blue-2h active:bg-blue-2a disabled:opacity-30 text-blue-8 fill-blue-6":
        variant === "light" && !textOnly,
      "bg-white hover:bg-white-7 active:bg-white-8 disabled:bg-white-disabled text-blue-8 fill-blue-6":
        variant === "white" && !textOnly,
      "bg-white hover:bg-blue-7 active:bg-blue-8 disabled:bg-white-disabled text-blue-8 hover:text-white border border-solid border-blue-8":
        variant === "whiteBorder" && !textOnly,

      "text-blue-8 fill-blue-8":
        (variant === "light" ||
          variant === "white" ||
          variant === "orange" ||
          variant === "blue") &&
        textOnly &&
        darkerText,
      "text-blue-5 fill-blue-5 hover:text-blue-8 hover:fill-blue-8":
        (variant === "light" || variant === "white") && textOnly && !darkerText,
      "text-blue-3 fill-blue-3 hover:text-blue-2 hover:fill-blue-2":
        variant === "dark" && textOnly,

      "flex align-center": arrow,

      "w-full block text-center justify-center": align === "wide",
      "md:w-auto md:inline-block w-full block text-center justify-center":
        mobileWide && align !== "wide",
      "opacity-50": disabled,

      "text-base py-1 md:text-lg md:py-2":
        variant === "orange" || variant === "blue",
    }
  );

  const arrowClassNames = classnames("inline-block", {
    "w-[20px] ms-2": size === "md" || size === "lg",
    "w-[16px] ms-1": size === "sm",
  });

  const openPDF = async () => {
    if (!href) {
      return;
    }

    try {
      const response = await fetch(href);

      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      window.open(url, "_blank");
    } catch (error) {
      console.error("Error fetching the PDF:", error);
    }
  };

  if ((!href && onClick) || (href && downloadAndOpenTab)) {
    return (
      <button
        onClick={downloadAndOpenTab ? openPDF : onClick}
        className={classNames}
        data-plan={ga4PlanSegment}
        data-item={ga4Id}
      >
        <span
          className={classnames("inline-block", { "whitespace-nowrap": !wrap })}
          data-item={ga4Id}
          dangerouslySetInnerHTML={{
            __html: text,
          }}
        />
        {arrow && <span className={arrowClassNames}>{rightArrow}</span>}
        {chevron && <span className={arrowClassNames}>{caretRight}</span>}
      </button>
    );
  }

  return (
    <Link
      href={ctaHref ?? ""}
      target={target}
      rel={rel}
      className={classNames}
      data-plan={ga4PlanSegment}
      data-item={ga4Id}
      locale={false}
    >
      <span
        className={classnames("inline-block", { "whitespace-nowrap": !wrap })}
        dangerouslySetInnerHTML={{ __html: text }}
        data-item={ga4Id}
      />
      {arrow && <span className={arrowClassNames}>{rightArrow}</span>}
      {chevron && <span className={arrowClassNames}>{caretRight}</span>}
    </Link>
  );
};

export default CustomButton;
