import React from "react";
import cn from "classnames";

import { RichText } from "@/components/atoms";
import BlockContent from "../molecules/BlockContent";

import {
  marginBMd,
  h6Text,
  textHeadlineNeutral,
  textHeadlineInverse,
  backgroundInverse,
  backgroundNeutral,
} from "@/constants/standardCSSClasses";
import { AttributesExtension } from "@/utilities/getBlockContentProps";
import { gql } from "@apollo/client";
import getEditorBlock from "@/fragments/fragmentFunctions/GetEditorBlock";
import CustomHeading from "../molecules/CustomHeading";
import { useTranslate } from "@/hooks/useTranslate";
import {
  BLOG_CASE_STUDY_COMPANY,
  BLOG_CASE_STUDY_COMPANY_SIZE,
  BLOG_CASE_STUDY_INDUSTRY,
} from "@/i18n/translations";

interface Props extends AttributesExtension {
  company?: string;
  content?: string;
  variant?: string;
  attributionName?: string;
  attributionPosition?: string;
  paddingTop?: string;
  paddingBottom?: string;
}

export default function CaseStudyBlock({
  company,
  content = "",
  variant = "white",
  attributionName = "",
  attributionPosition = "",
  paddingTop = "large",
  paddingBottom = "large",
  blockContentProps,
}: Props) {
  const { translate } = useTranslate();

  const headerClassNames = cn("text-3xl/[26px] font-medium", marginBMd, {
    [textHeadlineInverse]: variant === "dark",
    [textHeadlineNeutral]: variant !== "dark",
  });

  const blockClassNames = cn({
    [backgroundInverse]: variant === "dark",
    [backgroundNeutral]: variant === "light",
  });

  const pTextClassNames = cn("text-base/[20px]", {
    [textHeadlineInverse]: variant === "dark",
    [textHeadlineNeutral]: variant !== "dark",
  });

  const h6ClassNames = cn("mb-5 mt-0", h6Text, {
    [textHeadlineInverse]: variant === "dark",
    [textHeadlineNeutral]: variant !== "dark",
  });

  const boldClassNames = cn("font-medium", pTextClassNames);

  const companyHeader = translate(BLOG_CASE_STUDY_COMPANY);
  const industry = translate(BLOG_CASE_STUDY_INDUSTRY);
  const companySize = translate(BLOG_CASE_STUDY_COMPANY_SIZE);

  return (
    <BlockContent
      className={blockClassNames}
      width="constrain"
      defaultValue="box"
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      constrain
      {...blockContentProps}
    >
      <div className="grid grid-cols-1 items-start">
        <div className="w-full">
          <CustomHeading
            heading={companyHeader}
            headingClassNames={headerClassNames}
          />
          <RichText
            className={cn(
              "![&_a]:text-blue-5 mb-2 text-blue-5 hover:no-underline [&_a]:no-underline",
              boldClassNames
            )}
            tag="p"
          >
            {company}
          </RichText>
          <RichText className={cn("mt-0", pTextClassNames)} tag="p">
            {content}
          </RichText>
        </div>
        <div className="mt-8 flex flex-row gap-8">
          <div>
            <h6 className={h6ClassNames}>{industry}</h6>
            <RichText className={boldClassNames} tag="p">
              {attributionName}
            </RichText>
          </div>
          <div>
            <h6 className={h6ClassNames}>{companySize}</h6>
            <RichText className={boldClassNames} tag="p">
              {attributionPosition}
            </RichText>
          </div>
        </div>
      </div>
      <div className="mt-8 hidden border-b border-b-blue-2 md:block"></div>
    </BlockContent>
  );
}

// Must match __typename
const BLOCK_NAME = "TenupCaseStudy";

CaseStudyBlock.displayName = BLOCK_NAME;

CaseStudyBlock.fragments = {
  key: `${BLOCK_NAME}BlockFragment`,
  entry: gql`
    fragment ${BLOCK_NAME}BlockFragment on ${BLOCK_NAME} {
      ${getEditorBlock()}
      attributes {
        ... on ${BLOCK_NAME}Attributes {
          company
          content
          className
          metadata
          variant
          attributionName
          attributionPosition
          paddingTop
          paddingBottom
        }
      }
    }
  `,
};
