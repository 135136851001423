import classnames from "classnames";
import CustomImage from "../molecules/CustomImage";

export interface ValidationLogoInterface {
  full?: string;
  medium?: string;
  alt?: string;
  variant?: string;
  addVariant?: boolean;
  className?: string;
}

const ValidationLogo = ({
  full = "",
  medium = "",
  alt = "",
  variant = "light",
  addVariant = true,
  className = "",
}: ValidationLogoInterface) => {
  const wrapClassName = classnames("text-center", className, {
    "h-10": addVariant,
    "h-[144px]": !addVariant,
  });

  const classNames = classnames("w-full inline-block", {
    "dark-green-image":
      (variant === "light" || variant === "white") && addVariant,
    "white-image": variant === "dark" && addVariant,
    "h-10": addVariant,
    "h-[144px]": !addVariant,
  });

  const imageClassNames = classnames("!relative", {
    "h-10": addVariant,
    "h-[144px]": !addVariant,
  });

  return (
    <div className={wrapClassName}>
      <CustomImage
        full={medium || full}
        alt={alt}
        className={classNames}
        objectFit="contain"
        imageClassName={imageClassNames}
        rounded={false}
      />
    </div>
  );
};

export default ValidationLogo;
