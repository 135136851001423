import React from "react";
import cn from "classnames";
import BlockContent from "../molecules/BlockContent";
import AboveHeading from "../molecules/AboveHeading";
import ValidationLogo, {
  ValidationLogoInterface,
} from "../molecules/ValidationLogo";
import {
  backgroundInverse,
  backgroundNeutral,
} from "@/constants/standardCSSClasses";
import { marginTMd } from "@/constants/standardCSSClasses";
import { AttributesExtension } from "@/utilities/getBlockContentProps";
import { gql } from "@apollo/client";
import getEditorBlock from "@/fragments/fragmentFunctions/GetEditorBlock";

interface Props extends AttributesExtension {
  includeAboveHeading?: boolean;
  includeVariantToLogos?: boolean;
  includeRoundedBorders?: boolean;
  includeFullWidthBackground?: boolean;
  aboveHeading?: string;
  variant?: string;
  paddingTop?: string;
  paddingBottom?: string;
  width?: string;
  images: Array<ValidationLogoInterface>;
  contentType: string;
}

export default function ValidationBlock({
  includeAboveHeading = true,
  includeVariantToLogos = true,
  includeRoundedBorders = true,
  includeFullWidthBackground = false,
  aboveHeading = "",
  variant = "white",
  paddingTop = "default",
  paddingBottom = "default",
  images,
  contentType,
  width = "full",
  blockContentProps,
}: Props) {
  const logos = [...images, ...images];

  return (
    <BlockContent
      contentType={contentType}
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      width="full"
      defaultValue="box"
      constrain
      variant={width === "full" && includeFullWidthBackground ? variant : ""}
      {...blockContentProps}
    >
      <div
        className={cn("max-h[124] w-full overflow-hidden", {
          [backgroundInverse]: variant === "dark",
          [backgroundNeutral]: variant === "light",
          "rounded-full": includeRoundedBorders,
        })}
      >
        {aboveHeading && includeAboveHeading && (
          <AboveHeading
            className={cn("hidden pt-6 text-center md:block")}
            variant={variant}
          >
            {aboveHeading}
          </AboveHeading>
        )}
        <div
          className={cn(
            "flex w-max animate-logo-slide-carousel flex-nowrap items-center justify-center gap-10 md:hover:[animation-play-state:paused]",
            {
              [marginTMd]: !!aboveHeading && includeAboveHeading,
              "pb-4": includeAboveHeading,
            }
          )}
        >
          {logos.map((each, index) => (
            <ValidationLogo
              key={index}
              variant={variant}
              addVariant={includeVariantToLogos}
              {...each}
            />
          ))}
        </div>
      </div>
    </BlockContent>
  );
}

// Must match __typename
const BLOCK_NAME = "TenupValidation";

ValidationBlock.displayName = BLOCK_NAME;

ValidationBlock.fragments = {
  key: `${BLOCK_NAME}BlockFragment`,
  entry: gql`
    fragment ${BLOCK_NAME}BlockFragment on ${BLOCK_NAME} {
      ${getEditorBlock()}
      attributes {
        ... on ${BLOCK_NAME}Attributes {
          aboveHeading
          className
          images
          includeAboveHeading
          includeVariantToLogos
          includeRoundedBorders
          includeFullWidthBackground
          metadata
          paddingBottom
          paddingTop
          variant
          width
        }
      }
    }
  `,
};
