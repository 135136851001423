export const T2S_SUBTITLE = {
  "en": "Book a call",
  "fr": "Réserver un appel",
  "de": "Einen Anruf buchen",
  "es": "Reservar una llamada",
  "pt-br": "Book a call",
  "it": "Book a call",
  "ja": "Book a call",
  "ko": "Book a call",
  "nl": "Book a call",
  "pt-pt": "Book a call",
  "sv": "Book a call",
  "zh": "Book a call"
}