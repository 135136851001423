import cn from "classnames";
import {
  FacebookIcon,
  LinkedInIcon,
  RedditIcon,
  TwitterIcon,
} from "@/components/icons";
import { useTranslate } from "@/hooks/useTranslate";
import { POST_SHARE_TITLE_V2 } from "@/i18n/translations";

interface Props {
  url: string;
  title: string;
  className?: string;
  shareStyle?: string;
}

export default function PostShareIcons({ url, title, className }: Props) {
  const { translate } = useTranslate();

  const pClasses = cn("m-2 text-2xl font-medium text-blue-8");
  const linkClasses = cn("mx-2 text-xl font-bold uppercase");

  const iconsClasses = cn("flex");

  const iconClasses = cn("h-4 w-4 text-blue-8");

  const encodedTitle = encodeURIComponent(title);
  return (
    <div className={className}>
      <p className={pClasses}>{translate(POST_SHARE_TITLE_V2)}</p>
      <div className={iconsClasses}>
        <a
          className={linkClasses}
          href={`https://www.facebook.com/sharer.php?u=${url}`}
          target="_blank"
          rel="noreferrer"
        >
          <FacebookIcon className={iconClasses} />
        </a>
        <a
          className={linkClasses}
          href={`https://twitter.com/share?url=${url}`}
          target="_blank"
          rel="noreferrer"
        >
          <TwitterIcon className={iconClasses} />
        </a>
        <a
          className={linkClasses}
          href={`https://linkedin.com/shareArticle?url=${url}&title=${encodedTitle}`}
          target="_blank"
          rel="noreferrer"
        >
          <LinkedInIcon className={iconClasses} />
        </a>
        <a
          className={linkClasses}
          href={`https://reddit.com/submit?url=${url}&title=${encodedTitle}`}
          target="_blank"
          rel="noreferrer"
        >
          <RedditIcon className={iconClasses} />
        </a>
      </div>
    </div>
  );
}
