export const PRIVACY_CONSENT_TITLE_REDESIGN = {
  "en": "Please note: We use cookies on our site to give you the best experience. Non-essential cookies may be deactivated by selecting \"Reject non-essential cookies\" below. We honor global \"do not track\" and equivalent settings in your browser. Please select your settings here:",
  "fr": "Attention : Nous utilisons des cookies sur notre site pour vous offrir la meilleure expérience. Vous pouvez désactiver les cookies non essentiels en sélectionnant « Refuser les cookies non essentiels » ci-dessous. Nous respectons les préférences globales « Ne pas suivre » et les paramètres équivalents dans votre navigateur. Veuillez sélectionner vos paramètres ici :",
  "de": "Bitte beachten Sie: Wir verwenden Cookies auf unserer Website, um Ihnen das bestmögliche Erlebnis zu bieten. Wenn Sie nicht essenzielle Cookies deaktivieren möchten, klicken Sie bitte unten auf „Nicht essenzielle Cookies ablehnen“. Wir halten uns an globale „Do not track“ und gleichwertige Einstellungen in Ihrem Browser. Wählen Sie Ihre Einstellungen bitte hier:",
  "es": "Nota: usamos cookies en nuestro sitio para ofrecerle la mejor experiencia. Las cookies no esenciales se pueden desactivar seleccionando «Rechazar cookies no esenciales» más abajo. Respetamos las opciones globales de «no rastrear» y equivalentes en su navegador. Seleccione sus opciones aquí:",
  "pt-br": "Please note: We use cookies on our site to give you the best experience. Non-essential cookies may be deactivated by selecting \"Reject non-essential cookies\" below. We honor global \"do not track\" and equivalent settings in your browser. Please select your settings here:",
  "it": "Please note: We use cookies on our site to give you the best experience. Non-essential cookies may be deactivated by selecting \"Reject non-essential cookies\" below. We honor global \"do not track\" and equivalent settings in your browser. Please select your settings here:",
  "ja": "Please note: We use cookies on our site to give you the best experience. Non-essential cookies may be deactivated by selecting \"Reject non-essential cookies\" below. We honor global \"do not track\" and equivalent settings in your browser. Please select your settings here:",
  "ko": "Please note: We use cookies on our site to give you the best experience. Non-essential cookies may be deactivated by selecting \"Reject non-essential cookies\" below. We honor global \"do not track\" and equivalent settings in your browser. Please select your settings here:",
  "nl": "Please note: We use cookies on our site to give you the best experience. Non-essential cookies may be deactivated by selecting \"Reject non-essential cookies\" below. We honor global \"do not track\" and equivalent settings in your browser. Please select your settings here:",
  "pt-pt": "Please note: We use cookies on our site to give you the best experience. Non-essential cookies may be deactivated by selecting \"Reject non-essential cookies\" below. We honor global \"do not track\" and equivalent settings in your browser. Please select your settings here:",
  "sv": "Please note: We use cookies on our site to give you the best experience. Non-essential cookies may be deactivated by selecting \"Reject non-essential cookies\" below. We honor global \"do not track\" and equivalent settings in your browser. Please select your settings here:",
  "zh": "Please note: We use cookies on our site to give you the best experience. Non-essential cookies may be deactivated by selecting \"Reject non-essential cookies\" below. We honor global \"do not track\" and equivalent settings in your browser. Please select your settings here:"
}