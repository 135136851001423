import Cookies from "js-cookie";

interface GDPRCookie {
  country: string;
  region: string;
  isGDPR: boolean;
}

export function getGDPRCookie(): GDPRCookie | void {
  try {
    const gdprCookie = Cookies.get("gdprCookie");

    if (gdprCookie) {
      return JSON.parse(gdprCookie);
    }
  } catch (error) {
    console.error(`Failed to parse gdprCookie ${error}`);
  }
}
