export const T2S_SELECT_DATE_DESCRIPTION = {
  "en": "You’ll be redirected to a third-party provider.",
  "fr": "Vous serez redirigé vers un fournisseur tiers.",
  "de": "Sie werden zu einem Drittanbieter weitergeleitet.",
  "es": "Se le redirigirá a un proveedor externo.",
  "pt-br": "You’ll be redirected to a third-party provider.",
  "it": "You’ll be redirected to a third-party provider.",
  "ja": "You’ll be redirected to a third-party provider.",
  "ko": "You’ll be redirected to a third-party provider.",
  "nl": "You’ll be redirected to a third-party provider.",
  "pt-pt": "You’ll be redirected to a third-party provider.",
  "sv": "You’ll be redirected to a third-party provider.",
  "zh": "You’ll be redirected to a third-party provider."
}