import Link from "next/link";

import {
  BLOG_ORIGINALLY_PUBLISHED,
  BLOG_LAST_UPDATED,
} from "@/i18n/translations";
import { FormatDate } from "@/components/atoms";
import type { Author } from "@/types";
import { useTranslate } from "@/hooks/useTranslate";

interface Props {
  date: string;
  modified?: string;
  author: Author;
  className?: string;
}

export default function PostInfo({ date, modified, author, className }: Props) {
  const { name, uri } = author.node;
  const { translate } = useTranslate();
  const separator = <span className="mx-2 inline-block">|</span>;

  const timeformat: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour12: false,
  };

  const dateObject = new Date(date);
  const formattedDate = !isNaN(dateObject.valueOf())
    ? dateObject.toLocaleDateString("en", timeformat)
    : null;

  const modifiedObject = modified ? new Date(modified) : null;
  const formattedModified =
    modifiedObject && !isNaN(modifiedObject.valueOf())
      ? modifiedObject.toLocaleDateString("en", timeformat)
      : null;

  let displayModifiedDate = false;
  if (
    !isNaN(dateObject.valueOf()) &&
    modifiedObject &&
    !isNaN(modifiedObject.valueOf())
  ) {
    if (modifiedObject > dateObject) {
      displayModifiedDate = true;
    }
  }

  const originallyPublished = translate(BLOG_ORIGINALLY_PUBLISHED);
  const lastUpdated = translate(BLOG_LAST_UPDATED);
  const hideAuthor = name === "Dashlane";

  return (
    <div className={className}>
      {date && modified && formattedDate !== formattedModified && (
        <>
          {originallyPublished && (
            <span className="mr-1 inline-block">{originallyPublished}</span>
          )}
          <time dateTime={date}>
            <FormatDate date={date} />
          </time>
          {displayModifiedDate && (
            <>
              {separator}
              {lastUpdated && (
                <span className="mr-1 inline-block">
                  {translate(BLOG_LAST_UPDATED)}
                </span>
              )}
              <time dateTime={modified}>
                <FormatDate date={modified} />
              </time>
            </>
          )}
        </>
      )}
      {date && (!modified || formattedDate === formattedModified) && (
        <time dateTime={date}>
          <FormatDate date={date} />
        </time>
      )}
      {date && name && !hideAuthor && separator}
      {!hideAuthor && (
        <span>
          <Link href={uri}>{name}</Link>
        </span>
      )}
    </div>
  );
}
