import { HeadingBlock } from "@/components/blocks";
import type { Block as BlockType } from "@/types";
import { getBlockContentProps } from "@/utilities/getBlockContentProps";

import { observeHeadings } from "../utilities/observeHeadings";
import { useEffect, useState } from "react";
import { useTranslate } from "@/hooks/useTranslate";
import { TABLE_OF_CONTENTS_TITLE } from "@/i18n/translations";
interface Props {
  blocks: BlockType[];
  contentType?: string;
}

function scrollToHeading(id: string) {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({ behavior: "smooth" });
  }
}

export default function TableOfContents({ blocks, contentType }: Props) {
  const [activeId, setActiveId] = useState<string | undefined>();
  const { translate } = useTranslate();
  useEffect(() => {
    observeHeadings((id) => {
      setActiveId(id);
    });
  }, []);

  return (
    <div className="flex flex-col rounded bg-blue-1 px-6 py-9">
      <h2 className="mb-4 text-2xl text-blue-8">
        {translate(TABLE_OF_CONTENTS_TITLE)}
      </h2>
      <ul className="list-none">
        {blocks.map((block: BlockType, index: number) => {
          const { attributes: atts, name } = block;
          if (name !== "core/heading" || atts.level !== 2) {
            return null;
          }

          const attributes = {
            ...atts,
            contentType,
            blockContentProps: getBlockContentProps(atts),
          };
          const currentId = atts.content.replace(/ /g, "-").toLowerCase();
          switch (name) {
            case "core/heading":
              return (
                <li
                  className={`cursor-pointer p-3 hover:rounded hover:bg-blue-2 [&>div>h2>strong]:font-normal [&>div>h2]:text-lg [&>div>h2]:font-normal [&>div>h2]:text-grey-6 [&>div]:m-0 ${
                    activeId === currentId ? "rounded bg-blue-2" : ""
                  }`}
                  data-id={currentId}
                  onClick={() => {
                    setActiveId(currentId);
                    scrollToHeading(
                      atts.anchor
                        ? atts.anchor
                        : atts.content.replace(/ /g, "-").toLowerCase()
                    );
                  }}
                >
                  <HeadingBlock {...attributes} key={index} />
                </li>
              );
            default:
              return null;
          }
        })}
      </ul>
    </div>
  );
}
