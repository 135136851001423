import cn from "classnames";
import { READ_MORE_BUTTON } from "@/i18n/translations/READ_MORE_BUTTON";
import { useTranslate } from "@/hooks/useTranslate";
import { rightArrow } from "@/constants/icons";
import Link from "next/link";

interface Props {
  href: string;
  label?: string;
  className?: string;
  arrow?: boolean;
}

export default function ReadMore({ href, className, arrow = false }: Props) {
  const { translate } = useTranslate();

  return (
    <Link
      href={href}
      className={cn(
        "mt-3 inline-block font-bold uppercase underline transition hover:text-dashgreen-text",
        className
      )}
    >
      {translate(READ_MORE_BUTTON)}
      {arrow && (
        <span className="ms-2 inline-block w-[20px] align-middle">
          {rightArrow}
        </span>
      )}
    </Link>
  );
}
