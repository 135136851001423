export const T2S_TITLE = {
  "en": "Secure your booking request",
  "fr": "Sécurisez votre demande de réservation",
  "de": "Sichern Sie sich Ihre Buchungsanfrage",
  "es": "Asegure su solicitud de reserva",
  "pt-br": "Secure your booking request",
  "it": "Secure your booking request",
  "ja": "Secure your booking request",
  "ko": "Secure your booking request",
  "nl": "Secure your booking request",
  "pt-pt": "Secure your booking request",
  "sv": "Secure your booking request",
  "zh": "Secure your booking request"
}