export const PRIVACY_CONSENT_REFUSE_REDESIGN = {
  "en": "Reject non-essential cookies",
  "fr": "Refuser les cookies non essentiels",
  "de": "Nicht notwendige Cookies ablehnen",
  "es": "Rechazar cookies no esenciales",
  "pt-br": "Reject non-essential cookies",
  "it": "Reject non-essential cookies",
  "ja": "Reject non-essential cookies",
  "ko": "Reject non-essential cookies",
  "nl": "Reject non-essential cookies",
  "pt-pt": "Reject non-essential cookies",
  "sv": "Reject non-essential cookies",
  "zh": "Reject non-essential cookies"
}