import author from "./author";
import category from "./category";
import frontPage from "./front-page";
import page from "./page";
import pageMostRecentPosts from "./page-most-recent-posts";
import single from "./single";
import singleResource from "./single-resource";
import taxonomyResourceType from "./taxonomy-resource_type";
import taxonomyResourceCategory from "./taxonomy-resource_category";
import taxonomyResourceCategoryWebinars from "./taxonomy-resource_category-webinars-events";
import tag from "./tag";
import pageMarketing from "./page_marketing";
import pageLanding from "./page_landing";
import pageFormSuccess from "./page_form_success";
import pageTrialSuccess from "./page_trial_success";
import pageTalkToSales from "./page_talk_to_sales";
import blogHome from "./page_blog_home";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  author,
  category,
  "front-page": frontPage,
  page,
  "page-most-recent-posts": pageMostRecentPosts,
  single,
  "single-resource": singleResource,
  "taxonomy-resource_type": taxonomyResourceType,
  "taxonomy-resource_category": taxonomyResourceCategory,
  "taxonomy-resource_category-webinars-events":
    taxonomyResourceCategoryWebinars,
  tag,
  "template-Marketing Page": pageMarketing,
  "template-Landing Page": pageLanding,
  "template-Form Success Page": pageFormSuccess,
  "template-Trial Success Page": pageTrialSuccess,
  "template-Talk To Sales Page": pageTalkToSales,
  "template-Blog Home": blogHome,
};
