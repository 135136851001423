import React, { useEffect, useState } from "react";
import { useRouter } from "next/router";
import type { AppProps } from "next/app";
import Cookies from "js-cookie";
import { FaustProvider } from "@faustwp/core";

import TypeNameContext from "@/contexts/typeNameContext";

import "@/faust.config";
import "@/styles/globals.css";
import "@faustwp/core/dist/css/toolbar.css";
import CookieBanner from "@/components/organisms/CookieBanner";
import GTM from "@/components/atoms/GTM";
import GoogleAnalytics from "@/components/atoms/GoogleAnalytics";
import VWO from "@/components/atoms/VWO";
import userFunnel from "@/utilities/analytics/userFunnel";
import { getGDPRCookie } from "@/utilities/getGDPRCookie";
import Head from "next/head";

let didInit = false;

export default function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();
  const [showCookieBanner, setShowCookieBanner] = useState(false);
  const [isImplicitConsent, setIsImplicitConsent] = useState(false);
  const [loadTrackers, setLoadTrackers] = useState({
    analytics: false,
    marketing: false,
  });

  useEffect(() => {
    if (!didInit) {
      didInit = true;
      // ✅ Only runs once per app load
      let isGDPR = false;

      document.documentElement.style.setProperty(
        "--scrollbar-width",
        window.innerWidth - document.documentElement.clientWidth + "px"
      );

      const userPrivacyCookie = Cookies.get("userPrivacyCookie");
      const gdprCookie = getGDPRCookie();

      if (gdprCookie) {
        isGDPR = gdprCookie.isGDPR;
        const region = gdprCookie.region;
        try {
          window.pageConfig = {
            ...window.pageConfig,
            country: gdprCookie.country,
            GDPR: isGDPR,
          };
        } catch (error) {
          // Log error
        }

        if (userPrivacyCookie) {
          const parsedUserPrivacyCookie = JSON.parse(userPrivacyCookie);
          setLoadTrackers({
            analytics: parsedUserPrivacyCookie.analytics.enabled,
            marketing: parsedUserPrivacyCookie.marketing.enabled,
          });
          setShowCookieBanner(false);
        } else {
          if (isGDPR || region === "California" || region === "Virginia") {
            setShowCookieBanner(true);
          } else {
            setIsImplicitConsent(true);
          }
        }
      }
    }
  }, []);

  useEffect(() => {
    if (loadTrackers.marketing) {
      userFunnel.init(Cookies, process.env.NODE_ENV === "production");
    }
  }, [loadTrackers]);

  useEffect(() => {
    const drift = document.getElementById("drift-iframe");
    if (showCookieBanner && drift) {
      drift.hidden = true;
    } else if (drift) {
      drift.hidden = false;
    }
  }, [showCookieBanner]);

  return (
    <FaustProvider pageProps={pageProps}>
      <Head>
        <link
          href="https://fonts.googleapis.com/css2?family=Public+Sans:wght@400;600;700&display=swap"
          rel="stylesheet"
        />
      </Head>
      <TypeNameContext.Provider value={pageProps?.__SEED_NODE__?.__typename}>
        <Component {...pageProps} key={router.asPath} locale={router.locale} />
        <CookieBanner
          isVisible={showCookieBanner}
          setShowCookieBanner={setShowCookieBanner}
          setLoadTrackers={setLoadTrackers}
          isImplicitConsent={isImplicitConsent}
        />
        {loadTrackers.marketing && (
          <>
            <GTM />
            <VWO />
          </>
        )}
        {loadTrackers.analytics && <GoogleAnalytics />}
      </TypeNameContext.Provider>
    </FaustProvider>
  );
}
