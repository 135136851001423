import React from "react";
import cn from "classnames";

import { RichText } from "@/components/atoms";
import BlockContent from "../molecules/BlockContent";
import AboveHeading from "../molecules/AboveHeading";
import CustomButtonGroup from "../molecules/CustomButtonGroup";
import { CustomButtonInterface } from "../molecules/CustomButton";

import {
  marginBMd,
  textHeadlineNeutral,
  textHeadlineInverse,
  h1Text,
  textBodyInverse,
  backgroundInverse,
  backgroundNeutral,
  h3Text,
  h4Text,
  textBodyNeutral,
  pTextL,
  h1TextLarge,
} from "@/constants/standardCSSClasses";
import BelowButtonsText from "@/components/molecules/BelowButtonsText";
import CustomHeading, {
  HeadingOptions,
} from "@/components/molecules/CustomHeading";
import CustomImage, {
  CustomImageInterface,
} from "@/components/molecules/CustomImage";
import { AttributesExtension } from "@/utilities/getBlockContentProps";
import { gql } from "@apollo/client";
import getEditorBlock from "@/fragments/fragmentFunctions/GetEditorBlock";

interface Props extends AttributesExtension {
  includeAboveHeading?: boolean;
  includeBelowButtons?: boolean;
  includeButtons?: boolean;
  includeContent?: boolean;
  includeStars?: boolean;
  aboveHeading?: string;
  heading?: string;
  headingSize?: string;
  headingOptions?: HeadingOptions;
  content?: string;
  variant?: string;
  buttonsNumber?: number;
  buttonOne?: CustomButtonInterface;
  buttonTwo?: CustomButtonInterface;
  belowButtons?: string;
  width?: string;
  contentType: string;
  logo: CustomImageInterface;
  downloadAndOpenTab?: boolean;
  paddingTop?: string;
  paddingBottom?: string;
}

export default function CTABlock({
  includeAboveHeading = false,
  includeBelowButtons = false,
  includeButtons = false,
  includeContent = false,
  includeStars = false,
  aboveHeading = "",
  heading = "",
  headingSize = "lg",
  headingOptions = {
    tag: 2,
  },
  content = "",
  variant = "white",
  buttonsNumber = 0,
  buttonOne,
  buttonTwo,
  belowButtons = "",
  width = "",
  contentType,
  logo,
  blockContentProps,
  downloadAndOpenTab = false,
  paddingTop = "large",
  paddingBottom = "large",
}: Props) {
  const blockClassNames = cn("relative", {
    rounded: width !== "full",
    [backgroundInverse]: variant === "dark",
    [backgroundNeutral]: variant === "light",
  });

  buttonOne = typeof buttonOne === "string" ? JSON.parse(buttonOne) : buttonOne;
  buttonTwo = typeof buttonTwo === "string" ? JSON.parse(buttonTwo) : buttonTwo;

  const headerClassNames = cn("text-center", marginBMd, {
    [textHeadlineInverse]: variant === "dark",
    [textHeadlineNeutral]: variant !== "dark",
    [h1TextLarge]: headingSize === "xl",
    [h1Text]: headingSize === "lg",
    [h3Text]: headingSize === "md",
    [h4Text]: headingSize === "sm",
  });

  const pTextClassNames = cn("max-w-xl mx-auto text-center", pTextL, {
    [textBodyInverse]: variant === "dark",
    [textBodyNeutral]: variant !== "dark",
  });

  const starsClassNames = cn("text-center", marginBMd, {
    [textHeadlineInverse]: variant === "dark",
    [textHeadlineNeutral]: variant !== "dark",
  });

  const logoClassNames = cn("object-contain m-auto h-[29px] md:h-[39px]", {
    "white-image": variant === "dark",
  });

  const buttons = [];

  if (buttonsNumber > 0) {
    buttons.push({
      ...buttonOne,
      variant: variant === "dark" ? "white" : "dark",
    });
  }

  if (buttonsNumber > 1) {
    buttons.push({
      ...buttonTwo,
      variant: variant === "dark" ? "dark" : "light",
    });
  }

  return (
    <BlockContent
      contentType={contentType}
      className={blockClassNames}
      width={width}
      defaultValue="box"
      narrow
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      {...blockContentProps}
    >
      {includeStars && (
        <div className={starsClassNames}>
          <span className="me-2">&#9733;</span>
          <span className="me-2">&#9733;</span>
          <span className="me-2">&#9733;</span>
          <span className="me-2">&#9733;</span>
          &#9733;
        </div>
      )}
      {includeAboveHeading && (
        <AboveHeading variant={variant} className="text-center">
          {aboveHeading}
        </AboveHeading>
      )}
      <CustomHeading
        heading={heading}
        headingOptions={headingOptions}
        headingClassNames={headerClassNames}
      />
      {includeContent && (
        <RichText className={pTextClassNames} tag="p">
          {content}
        </RichText>
      )}
      {includeButtons && (
        <CustomButtonGroup
          align="center"
          buttons={buttons}
          downloadAndOpenTab={downloadAndOpenTab}
        />
      )}
      {includeBelowButtons && (
        <BelowButtonsText centered={true} variant={variant}>
          {belowButtons}
        </BelowButtonsText>
      )}
      {logo?.full && (
        <div className="mt-20 w-full md:absolute md:bottom-[60px] md:right-[60px] md:mt-0 md:max-w-44">
          <CustomImage
            className={logoClassNames}
            imageClassName="m-auto"
            objectFit="contain"
            {...logo}
          />
        </div>
      )}
    </BlockContent>
  );
}

// Must match __typename
const BLOCK_NAME = "TenupCta";

CTABlock.displayName = BLOCK_NAME;

CTABlock.fragments = {
  key: `${BLOCK_NAME}BlockFragment`,
  entry: gql`
    fragment ${BLOCK_NAME}BlockFragment on ${BLOCK_NAME} {
      ${getEditorBlock()}
      attributes {
        ... on ${BLOCK_NAME}Attributes {
          aboveHeading
          belowButtons
          buttonOne
          buttonTwo
          buttonsNumber
          className
          content
          downloadAndOpenTab
          ga4SectionId
          heading
          headingOptions
          headingSize
          includeAboveHeading
          includeBelowButtons
          includeButtons
          includeContent
          includeStars
          logo
          metadata
          variant
          width
        }
      }
    }
  `,
};
