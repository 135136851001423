import cn from "classnames";
import {
  backgroundInverse,
  backgroundNeutral,
} from "@/constants/standardCSSClasses";
import EmailCaptureForm from "@/components/molecules/EmailCaptureForm";
import { SIGNUP_FORM_TITLE } from "@/i18n/translations";
import { useTranslate } from "@/hooks/useTranslate";
import { useState } from "react";

interface Props {
  width?: string;
  variant?: string;
}

export default function SignUpForm({
  width = "box",
  variant = "white",
}: Props) {
  const { translate } = useTranslate();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const titleGridClass = !isSubmitted ? "md:grid-cols-2" : "md:grid-cols-1";
  const blockClassNames = cn({
    "w-bleed-full max-w-none": width === "full",
    [backgroundInverse]: variant === "dark",
    [backgroundNeutral]: variant === "light",
  });

  const innerClassNames = cn({
    "max-w-7xl px-4 md:px-20": width === "full",
    "mx-auto w-full max-w-4xl": width === "box",
  });

  return (
    <div className={blockClassNames}>
      <div className={cn("mx-auto flex flex-col", innerClassNames)}>
        <div
          className={`grid grid-cols-1 items-center justify-center gap-4 py-16 ${titleGridClass}`}
        >
          {!isSubmitted && (
            <p className="max-w-md text-2xl font-medium md:text-3xl">
              {translate(SIGNUP_FORM_TITLE)}
            </p>
          )}
          <EmailCaptureForm setIsSubmitted={setIsSubmitted} />
        </div>
      </div>
    </div>
  );
}
