export const parseStringToJson = <T>(
  value: string | T | null | undefined
): T => {
  if (!value) {
    return {} as T;
  }

  if (typeof value !== "string") {
    return value as T;
  }

  try {
    return JSON.parse(value);
  } catch (error) {
    console.error("Error parsing JSON string:", error);
    return {} as T;
  }
};
