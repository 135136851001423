import React, { useState, useEffect } from "react";
import cn from "classnames";

import { RichText } from "@/components/atoms";
import AboveHeading from "../molecules/AboveHeading";
import CustomButtonGroup from "../molecules/CustomButtonGroup";
import { CustomButtonInterface } from "../molecules/CustomButton";
import { CustomImageInterface } from "../molecules/CustomImage";
import CustomMedia, {
  MediaObjectInterface,
} from "@/components/molecules/CustomMedia";

import {
  marginBMd,
  textHeadlineNeutral,
  textHeadlineInverse,
  textBodyInverse,
  pTextL,
  textBodyNeutral,
  backgroundInverse,
  backgroundNeutral,
  h3Text,
  backgroundWhite,
} from "@/constants/standardCSSClasses";
import BelowButtonsText from "@/components/molecules/BelowButtonsText";
import CustomHeading, {
  HeadingOptions,
} from "@/components/molecules/CustomHeading";
import { iconClose } from "@/constants/icons";
import EmailCaptureForm from "@/components/molecules/EmailCaptureForm";
import { getCookie, setCookie } from "@/utilities/cookies";
import getOfferCookieName from "@/utilities/getOfferCookieName";
import { useRouter } from "next/router";
import { gql } from "@apollo/client";
import getEditorBlock from "@/fragments/fragmentFunctions/GetEditorBlock";
import { parseStringToJson } from "@/utilities/parseStringToJson";

interface Props {
  includeAboveHeading?: boolean;
  includeBelowButtons?: boolean;
  includeButtons?: boolean;
  includeContent?: boolean;
  includeImage?: boolean;
  includeForm?: boolean;
  aboveHeading?: string;
  heading?: string;
  headingOptions?: HeadingOptions;
  content?: string;
  formButtonText?: string;
  inputPlaceholder?: string;
  variant?: string;
  modalDelay?: number;
  buttonsNumber?: number;
  buttonOne?: CustomButtonInterface;
  buttonTwo?: CustomButtonInterface;
  belowButtons?: string;
  imageSize?: string;
  image?: CustomImageInterface;
  media?: MediaObjectInterface;
  disableCookie?: boolean;
  offerId?: string;
  disableLanguage?: Record<string, boolean>;
  ga4SectionId?: string;
  displayMode?: "default" | "larger";
}

export default function OfferOverlayBlock({
  includeAboveHeading = true,
  includeBelowButtons = true,
  includeButtons = true,
  includeContent = true,
  includeImage = true,
  includeForm = false,
  aboveHeading = "",
  heading = "",
  headingOptions = {
    tag: 1,
  },
  content = "",
  formButtonText = "",
  variant = "white",
  modalDelay = 5,
  inputPlaceholder = "",
  buttonsNumber = 2,
  buttonOne: rawButtonOne,
  buttonTwo: rawButtonTwo,
  belowButtons = "",
  imageSize = "lg",
  image: rawImage,
  media: rawMedia,
  disableCookie = false,
  offerId = "",
  disableLanguage = {},
  ga4SectionId = "",
  displayMode = "default",
}: Props) {
  const [active, setActive] = useState(false);
  const cookieName = getOfferCookieName(offerId || "default");
  const { locale = "" } = useRouter();

  useEffect(() => {
    //Get the cookie for this bannerId
    const dismissed = getCookie(cookieName);
    let timer: NodeJS.Timeout | null = null;

    if (
      (disableLanguage === null || !disableLanguage[locale]) &&
      (!dismissed || disableCookie)
    ) {
      timer = setTimeout(() => {
        setActive(true);
      }, modalDelay * 1000);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, []);

  if (!active) return null;

  const dismiss = () => {
    //Set a cookie to save that this offer has been dismissed and expire the cookie tomorrow
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    setCookie(cookieName, "true", tomorrow.toUTCString());

    setActive(false);
  };

  // Parse the JSON data once
  const parsedButtonOne = parseStringToJson(rawButtonOne);
  const parsedButtonTwo = parseStringToJson(rawButtonTwo);
  const parsedImage = parseStringToJson(rawImage);
  const parsedMedia = parseStringToJson(rawMedia);

  const buttons = [];

  if (buttonsNumber > 0) {
    buttons.push({
      ...parsedButtonOne,
      align: "wide",
      variant: variant === "dark" ? "white" : "dark",
    });
  }

  if (buttonsNumber > 1) {
    buttons.push({
      ...parsedButtonTwo,
      align: "wide",
      variant: variant === "dark" ? "dark" : "light",
    });
  }

  const headingClassNames = cn(marginBMd, h3Text, {
    [textHeadlineInverse]: variant === "dark",
    [textHeadlineNeutral]: variant === "light",
  });

  const modalClasses = cn("relative w-full mx-auto rounded overflow-hidden", {
    [backgroundInverse]: variant === "dark",
    [backgroundNeutral]: variant === "light",
    [backgroundWhite]: variant === "white",
    "md:w-[838px]": includeImage && displayMode === "default",
    "md:w-[600px]": !includeImage && displayMode === "default",
    "max-w-[1080px]": displayMode === "larger",
    "w-full": displayMode === "larger",
    "h-full md:h-auto": includeImage,
  });

  const modalInnerClasses = cn("grid items-center", {
    "md:grid-cols-2": includeImage,
  });

  const closeButtonClasses = cn(
    "absolute right-4 top-4 z-10 h-6 w-6 [&>svg]:w-full",
    {
      "fill-grey-3": variant === "dark" || includeImage,
      "fill-grey-6": variant !== "dark" && !includeImage,
    }
  );

  const modalContentClasses = cn("px-4 py-10 md:h-auto md:px-10", {
    "h-offer-overlay-content overflow-y-auto": includeImage,
  });

  const imageSizeClassNames = cn({
    "1:1": imageSize === "lg",
    "2:1": imageSize === "md",
    "3:1": imageSize === "sm",
  });

  return (
    <dialog
      className="dialog fixed left-0 top-0 z-50 flex h-full w-full items-center justify-center bg-black/60 p-4"
      open={active}
      onClick={() => dismiss()}
      {...(ga4SectionId ? { "data-component": ga4SectionId } : {})}
    >
      <div className={modalClasses} onClick={(e) => e.stopPropagation()}>
        <button
          className={closeButtonClasses}
          dangerouslySetInnerHTML={{ __html: iconClose }}
          onClick={() => dismiss()}
        />
        <div className={modalInnerClasses}>
          <div className={modalContentClasses}>
            {includeAboveHeading && aboveHeading && (
              <AboveHeading variant={variant}>{aboveHeading}</AboveHeading>
            )}
            <CustomHeading
              heading={heading}
              headingClassNames={headingClassNames}
              headingOptions={headingOptions}
            />
            {includeContent && content && (
              <RichText
                className={cn({
                  [textBodyInverse]: variant === "dark",
                  [textBodyNeutral]: variant !== "dark",
                })}
                tag="p"
              >
                {content}
              </RichText>
            )}
            {includeButtons && !includeForm && (
              <CustomButtonGroup align="left" buttons={buttons} column={true} />
            )}
            {includeForm && (
              <div className="mt-10 [&_.text-red-600]:relative">
                <EmailCaptureForm
                  buttonText={formButtonText}
                  inputPlaceholder={inputPlaceholder}
                  placeholderSize={pTextL}
                  buttonTextSize="lg"
                  variant={variant}
                  column={true}
                />
              </div>
            )}
            {includeBelowButtons && (
              <BelowButtonsText variant={variant} centered={true}>
                {belowButtons}
              </BelowButtonsText>
            )}
          </div>
          {includeImage && (
            <div
              className={cn(
                "h-offer-overlay-image order-first md:order-2 md:h-full md:w-full"
              )}
            >
              <CustomMedia
                className="h-offer-overlay-image w-full md:h-full"
                media={parsedMedia ?? { parsedMedia: {}, type: "image" }}
                aspect={imageSizeClassNames}
                fallbackImage={parsedImage}
              />
            </div>
          )}
        </div>
      </div>
    </dialog>
  );
}

// Must match __typename
const BLOCK_NAME = "TenupOfferOverlay";

OfferOverlayBlock.displayName = BLOCK_NAME;

OfferOverlayBlock.fragments = {
  key: `${BLOCK_NAME}BlockFragment`,
  entry: gql`
    fragment ${BLOCK_NAME}BlockFragment on ${BLOCK_NAME} {
      ${getEditorBlock()}
      attributes {
        ... on ${BLOCK_NAME}Attributes {
          aboveHeading
          belowButtons
          buttonOne
          buttonTwo
          buttonsNumber
          className
          content
          disableCookie
          disableLanguage
          formButtonText
          ga4SectionId
          heading
          headingOptions
          headingSize
          image
          imageAlign
          includeAboveHeading
          includeButtons
          includeBelowButtons
          includeContent
          includeForm
          includeHeading
          includeImage
          inputPlaceholder
          metadata
          modalDelay
          offerId
          size
          variant
          displayMode
          media
          imageSize
        }
      }
    }
  `,
};
