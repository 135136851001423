export const BLOG_CASE_STUDY_COMPANY_SIZE = {
  "en": "Company size",
  "fr": "Taille de l'entreprise",
  "de": "Mitarbeiterzahl",
  "es": "Tamaño de la compañía",
  "pt-br": "Company size",
  "it": "Company size",
  "ja": "Company size",
  "ko": "Company size",
  "nl": "Company size",
  "pt-pt": "Company size",
  "sv": "Company size",
  "zh": "Company size"
}