import Link from "next/link";
import cn from "classnames";

import {
  BLOG_PUBLISHED,
  BLOG_UPDATED,
  BLOG_MIN_READ,
} from "@/i18n/translations";
import { FormatDate } from "@/components/atoms";
import type { Author } from "@/types";
import { useTranslate } from "@/hooks/useTranslate";
import Icon from "./Icon";
import { authorIcon, readTime } from "@/constants/icons";

interface Props {
  date: string;
  modified?: string;
  author: Author;
  className?: string;
  content?: string;
  isMobile?: boolean;
}

export default function PostIconInfo({
  date,
  modified,
  author,
  className,
  content,
  isMobile = false,
}: Props) {
  const { name, uri } = author.node;
  const { translate } = useTranslate();

  const timeformat: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour12: false,
  };

  const calculateReadingTime = (content: string, wordsPerMinute = 200) => {
    // Remove tags from content
    const text = content.replace(/<[^>]*>/g, "").trim();
    // Remove spaces
    const wordCount = text.split(/\s+/).length;
    // Calc time in minutes
    const readingTime = Math.ceil(wordCount / wordsPerMinute);

    return readingTime;
  };

  const dateObject = new Date(date);
  const formattedDate = !isNaN(dateObject.valueOf())
    ? dateObject.toLocaleDateString("en", timeformat)
    : null;

  const modifiedObject = modified ? new Date(modified) : null;
  const formattedModified =
    modifiedObject && !isNaN(modifiedObject.valueOf())
      ? modifiedObject.toLocaleDateString("en", timeformat)
      : null;

  const originallyPublished = translate(BLOG_PUBLISHED);
  const lastUpdated = translate(BLOG_UPDATED);
  const readMin = translate(BLOG_MIN_READ);

  const postInfoClassNames = cn(
    "flex flex-row gap-4 md:gap-8 text-blue-8 text-sm/[17px] mt-0 items-center",
    className
  );
  const iconClassNames = cn("w-5 y-5");

  const authorClassNames = cn("inline-block", {
    hidden: name === "Dashlane",
  });

  return (
    <div className={postInfoClassNames}>
      <div className={authorClassNames}>
        <>
          <Icon
            className={iconClassNames}
            source={authorIcon}
            variant="white"
          />
          <span className="ml-1 align-middle">
            <Link className="m-0 p-0 text-blue-8 hover:no-underline" href={uri}>
              {name}
            </Link>
          </span>
        </>
      </div>
      <div className="inline-block">
        {modified && formattedDate !== formattedModified && (
          <>
            {lastUpdated && (
              <span className="mr-1 inline-block">
                {translate(BLOG_UPDATED)}
              </span>
            )}
            <time dateTime={modified}>
              <FormatDate date={modified} />
            </time>
          </>
        )}
        {date &&
          originallyPublished &&
          (!modified || formattedDate === formattedModified) && (
            <>
              <span className="mr-1 inline-block">{originallyPublished}</span>
              <time dateTime={date}>
                <FormatDate date={date} />
              </time>
            </>
          )}
      </div>
      {content && !isMobile && (
        <div className="inline-block">
          <>
            <Icon
              className={iconClassNames}
              source={readTime}
              variant="white"
            />
            <span className="ml-1 align-middle">
              {calculateReadingTime(content)} {readMin}
            </span>
          </>
        </div>
      )}
    </div>
  );
}
