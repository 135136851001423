import React, { useState, useRef, TouchEvent, TouchEventHandler } from "react";
import { useWindowSize } from "@/hooks/useWindowSize";
import cn from "classnames";
import {
  h5Text,
  iconBorderInverse,
  iconBorderNeutral,
  iconFillInverse,
  iconFillNeutral,
  textBodyInverse,
} from "@/constants/standardCSSClasses";
import { htmlDecode } from "@/utilities/htmlDecode";
import { leftArrow, rightArrow } from "@/constants/icons";
import { MD_BREAK, MOBILE_BREAK } from "@/constants/utility";

export interface TouchSliderInterface {
  wrapClassNames: string;
  sliderClassNames: string;
  handleTouchStart: TouchEventHandler<HTMLDivElement>;
  handleTouchEnd: TouchEventHandler<HTMLDivElement>;
  sliderControls?: React.ReactNode;
}

export const useTouchSlider = (
  numSlides: number,
  variant: string,
  columns: number = 1,
  fullWidth: boolean = false,
  sliderTitles?: string[],
  showMobile: boolean = false
) => {
  const sliderRef = useRef<HTMLDivElement>(null);
  const [slide, setSlide] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [dragStartX, setDragStartX] = useState(0);
  const [startMargin, setStartMargin] = useState("0px");
  const { width } = useWindowSize();

  // Adjust the number of slides in situations where, for example, there are 4 slides and 3 columns
  if (width >= MOBILE_BREAK && numSlides % columns) {
    numSlides = numSlides + columns - (numSlides % columns);
  }

  const nextSlide = () => {
    //If we're above "md" width, we're moving an entire set of columns in each slide
    if (width >= MOBILE_BREAK && slide >= numSlides / columns - 1) return;

    //If we're below "md" width, we're always moving one at a time
    if (width < MOBILE_BREAK && slide >= numSlides - 1) return;

    setSlide(slide + 1);
  };

  const prevSlide = () => {
    if (slide === 0) return;

    setSlide(slide - 1);
  };

  const slideTo = (index: number) => {
    setSlide(index);
  };

  const shouldSlide = (direction: "left" | "right") => {
    return (
      (direction === "left" && slide > 0) ||
      (direction === "right" && slide < numSlides - 1)
    );
  };

  const checkDirection = (start: number, end: number) => {
    //alert(`touchstart: ${touchstartX}, touchend: ${touchendX}`);
    //Allow for a diagonal touch that may not be intending to swipe left or right
    if (end && start && end + 30 < start) nextSlide();
    if (end && start && end - 30 > start) prevSlide();
  };

  const handleTouchStart = (e: TouchEvent<HTMLDivElement>) => {
    const element = e?.target as HTMLAnchorElement;

    if (element?.tagName === "A" || element?.parentElement?.tagName === "A") {
      const url =
        element?.href !== undefined
          ? element?.href
          : (element?.parentElement as HTMLAnchorElement).href;

      if (url) window.location.href = url;
    }

    e.preventDefault();
    setDragStartX(e?.changedTouches[0]?.screenX ?? 0);

    setIsDragging(true);

    if (sliderRef.current) {
      const startMargin = window
        .getComputedStyle(sliderRef.current)
        .getPropertyValue("margin-inline-start");

      setStartMargin(startMargin);
    }
  };

  const handleTouchMove = (e: TouchEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (!sliderRef.current) return;
    if (!isDragging) return;

    sliderRef.current.style.marginInlineStart = `calc(${
      (e?.changedTouches[0]?.screenX ?? 0) - dragStartX
    }px + ${startMargin})`;
    sliderRef.current.style.transitionDuration = "0ms";
  };

  const handleTouchEnd = (e: TouchEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(false);

    if (sliderRef.current) {
      sliderRef.current.removeAttribute("style");
    }
    checkDirection(dragStartX, e?.changedTouches[0]?.screenX ?? 0);
  };

  //These all have to be printed out manually to trigger Tailwind to include them
  let sliderClassNames = cn(
    "flex w-[100vw] transition-all duration-500 ease-out",
    {
      "ms-[calc(-75vw-24px)] md:ms-[calc(-50vw-24px)]": slide === 1,
      "ms-[calc(-150vw-48px)] md:ms-[calc(-100vw-48px)]": slide === 2,
      "ms-[calc(-225vw-72px)] md:ms-[calc(-150vw-72px)]": slide === 3,
      "ms-[calc(-300vw-96px)] md:ms-[calc(-200vw-96px)]": slide === 4,
      "ms-[calc(-375vw-120px)] md:ms-[calc(-250vw-120px)]": slide === 5,
      "ms-[calc(-450vw-144px)] md:ms-[calc(-300vw-144px)]": slide === 6,
      "ms-[calc(-525vw-168px)] md:ms-[calc(-350vw-168px)]": slide === 7,
      "ms-[calc(-600vw-192px)] md:ms-[calc(-400vw-192px)]": slide === 8,
      "ms-[calc(-675vw-216px)] md:ms-[calc(-450vw-216px)]": slide === 9,
      "ms-[calc(-750vw-240px)] md:ms-[calc(-500vw-240px)]": slide === 10,
      "ms-[calc(-825vw-264px)] md:ms-[calc(-550vw-264px)]": slide === 11,
    }
  );

  let sliderInnovationClassNames = cn(
    "innovation-slider relative flex w-full h-[auto] transition-all md:transition-opacity duration-500 ease-out opacity-1",
    {
      "ms-[calc(-100%-24px)]": slide === 1,
      "ms-[calc(-200%-48px)]": slide === 2,
      "ms-[calc(-300%-72px)]": slide === 3,
      "ms-[calc(-400%-96px)]": slide === 4,
      "ms-[calc(-500%-120px)]": slide === 5,
      "ms-[calc(-600%-144px)]": slide === 6,
      "ms-[calc(-700%-168px)]": slide === 7,
      "ms-[calc(-800%-192px)]": slide === 8,
      "ms-[calc(-900%-216px)]": slide === 9,
      "ms-[calc(-1000%-240px)]": slide === 10,
      "ms-[calc(-1100%-264px)]": slide === 11,
    }
  );

  const fullWidthSliderClassNames = cn(
    "flex ms- w-[100%] h-auto md:h-[526px] transition-all duration-500 ease-out",
    {
      "ms-[calc(-75vw-24px)] md:ms-[calc(-100%-24px)]": slide === 1,
      "ms-[calc(-150vw-48px)] md:ms-[calc(-200%-48px)]": slide === 2,
      "ms-[calc(-225vw-72px)] md:ms-[calc(-300%-72px)]": slide === 3,
      "ms-[calc(-300vw-96px)] md:ms-[calc(-400%-96px)]": slide === 4,
      "ms-[calc(-375vw-120px)] md:ms-[calc(-500%-120px)]": slide === 5,
      "ms-[calc(-450vw-144px)] md:ms-[calc(-600%-144px)]": slide === 6,
      "ms-[calc(-525vw-168px)] md:ms-[calc(-700%-168px)]": slide === 7,
      "ms-[calc(-600vw-192px)] md:ms-[calc(-800%-192px)]": slide === 8,
      "ms-[calc(-675vw-216px)] md:ms-[calc(-900%-216px)]": slide === 9,
      "ms-[calc(-750vw-240px)] md:ms-[calc(-1000%-240px)]": slide === 10,
      "ms-[calc(-825vw-264px)] md:ms-[calc(-1100%-264px)]": slide === 11,
    }
  );

  if (columns === 3 || columns === 2) {
    sliderClassNames = cn(
      "flex transition-all duration-500 ease-out w-[calc(100%+24px)]",
      {
        "ms-[calc(-75vw-24px)] md:ms-[calc(-100%-24px)]": slide === 1,
        "ms-[calc(-150vw-48px)] md:ms-[calc(-200%-48px)]": slide === 2,
        "ms-[calc(-225vw-72px)] md:ms-[calc(-300%-72px)]": slide === 3,
        "ms-[calc(-300vw-96px)] md:ms-[calc(-400%-96px)]": slide === 4,
        "ms-[calc(-375vw-120px)] md:ms-[calc(-500%-120px)]": slide === 5,
        "ms-[calc(-450vw-144px)] md:ms-[calc(-600%-144px)]": slide === 6,
        "ms-[calc(-525vw-168px)] md:ms-[calc(-700%-168px)]": slide === 7,
        "ms-[calc(-600vw-192px)] md:ms-[calc(-800%-192px)]": slide === 8,
        "ms-[calc(-675vw-216px)] md:ms-[calc(-900%-216px)]": slide === 9,
        "ms-[calc(-750vw-240px)] md:ms-[calc(-1000%-240px)]": slide === 10,
        "ms-[calc(-825vw-264px)] md:ms-[calc(-1100%-264px)]": slide === 11,
      }
    );
  }

  const leftArrowClassNames = cn(
    "me-5 inline-block border border-solid rounded-full p-2",
    {
      [iconFillInverse]: variant === "dark",
      [iconFillNeutral]: variant !== "dark",
      [iconBorderInverse]: variant === "dark",
      [iconBorderNeutral]: variant !== "dark",
      "cursor-pointer": slide !== 0,
      "opacity-20 cursor-disabled": slide === 0,
    }
  );
  const rightArrowClassNames = cn(
    "me-5 inline-block border border-solid rounded-full p-2",
    {
      [iconFillInverse]: variant === "dark",
      [iconFillNeutral]: variant !== "dark",
      [iconBorderInverse]: variant === "dark",
      [iconBorderNeutral]: variant !== "dark",
      "cursor-pointer": slide !== numSlides / columns - 1,
      "opacity-20 cursor-disabled": slide === numSlides / columns - 1,
    }
  );

  const leftArrowInnerSlideClassNames = cn(
    "inline-block border border-solid rounded-full p-2 float-left",
    {
      [iconFillInverse]: variant === "dark" || variant !== "dark",
      [iconBorderInverse]: variant === "dark" || variant !== "dark",
      "cursor-pointer": slide !== 0,
      "opacity-20 cursor-disabled": slide === 0,
    }
  );
  const rightArrowInnerSlideClassNames = cn(
    "inline-block border border-solid rounded-full p-2 float-right",
    {
      [iconFillInverse]: variant === "dark" || variant !== "dark",
      [iconBorderInverse]: variant === "dark" || variant !== "dark",
      "cursor-pointer": slide !== numSlides / columns - 1,
      "opacity-20 cursor-disabled": slide === numSlides / columns - 1,
    }
  );

  const sliderTitlesNavigationClassNames = (slider: number, i: number) =>
    cn("cursor-pointer pb-2", h5Text, {
      "text-dashgreen border-b-2 border-dashgreen":
        variant !== "dark" && slider === i,
      [textBodyInverse]: variant === "dark" && slider !== i,
      "text-white border-b-2 border-white": variant === "dark" && slider === i,
    });

  const sliderDotsClassNames = (slider: number, i: number) =>
    cn("h-[8px] w-[8px] cursor-pointer  rounded-full", {
      "opacity-10": variant !== "dark" && slider !== i,
      "opacity-100": variant !== "dark" && slider === i,
      "bg-blue-8": variant !== "dark",
      "bg-grey-005": variant === "dark" && slider === i,
      "bg-neutral-080": variant === "dark" && slider !== i,
    });

  const handleFadeOnClick = (callback: Function) => {
    const innovationSlider = document.querySelector(
      ".innovation-slider"
    ) as HTMLDivElement;
    innovationSlider.style.opacity = "0";

    setTimeout(() => {
      callback();

      innovationSlider.style.opacity = "1";
    }, 300);
  };

  const innovationSliderHandler = (direction: "right" | "left") => {
    if (!shouldSlide(direction)) return;
    handleFadeOnClick(direction === "left" ? prevSlide : nextSlide);
  };

  const sliderDotsWrapperClassNames = cn(
    "mt-10 items-center justify-center gap-2",
    {
      "hidden md:flex": !showMobile,
      flex: showMobile,
    }
  );

  return {
    wrapClassNames: cn("overflow-hidden", {
      "w-bleed-right": !fullWidth,
    }),
    sliderClassNames,
    fullWidthSliderClassNames,
    sliderInnovationClassNames,
    handleTouchStart,
    handleTouchEnd,
    handleTouchMove,
    sliderRef,
    sliderControls: (
      <div className="hidden md:block md:w-[124px]">
        <span
          className={leftArrowClassNames}
          onClick={() => {
            prevSlide();
          }}
        >
          {leftArrow}
        </span>
        <span
          className={rightArrowClassNames}
          onClick={() => {
            nextSlide();
          }}
        >
          {rightArrow}
        </span>
      </div>
    ),
    sliderInnerControls: fullWidth && (
      <div className="absolute left-[5%] right-[5%] z-10 hidden md:top-[30%] md:block lg:top-[36%]">
        <span
          className={leftArrowInnerSlideClassNames}
          onClick={() => innovationSliderHandler("left")}
        >
          {leftArrow}
        </span>
        <span
          className={rightArrowInnerSlideClassNames}
          onClick={() => innovationSliderHandler("right")}
        >
          {rightArrow}
        </span>
      </div>
    ),
    sliderTitlesNavigation: fullWidth && (
      <div className="header-titles no-scrollbar overflow-x-auto md:overflow-hidden md:px-10">
        <div className="flex w-max flex-row items-center justify-center gap-10 whitespace-nowrap md:w-auto md:gap-12">
          {sliderTitles?.map((title, i) => (
            <div
              key={i}
              className={sliderTitlesNavigationClassNames(slide, i)}
              onClick={(e) => {
                if (width < MD_BREAK) {
                  slideTo(i);
                } else {
                  handleFadeOnClick(() => slideTo(i));
                }

                const item = e.currentTarget;
                const headerTitles = e.currentTarget.closest(
                  ".header-titles"
                ) as HTMLDivElement;

                if (!headerTitles) return;

                const itemWidth = item.offsetWidth;
                const scrollLeft = headerTitles.scrollLeft;
                const itemLeft = item.offsetLeft;

                const minScrollLeft =
                  itemLeft - (headerTitles.offsetWidth - itemWidth) / 2;

                if (itemLeft < scrollLeft) {
                  headerTitles.scrollLeft = minScrollLeft;
                } else if (
                  itemLeft + itemWidth >
                  scrollLeft + headerTitles.offsetWidth
                ) {
                  headerTitles.scrollLeft =
                    itemLeft + itemWidth - headerTitles.offsetWidth;
                }
              }}
            >
              {htmlDecode(title)}
            </div>
          ))}
        </div>
      </div>
    ),
    sliderDots: (
      <div className={sliderDotsWrapperClassNames}>
        {[...Array(numSlides).keys()].map((_, i) => (
          <div
            key={i}
            className={sliderDotsClassNames(slide, i)}
            onClick={() => {
              slideTo(i);
              // stopAutoSlide();
            }}
          />
        ))}
      </div>
    ),
  };
};

export const useTouchSlide = (columns: number = 1, fullWidth = false) => {
  return {
    slideWrapClass: cn("me-[24px] shrink-0 w-[75vw]", {
      "md:w-[50vw]": !fullWidth && columns === 1,
      "md:w-[calc(50%-24px)]": !fullWidth && columns === 2,
      "md:w-[calc(33.3333%-24px)]": !fullWidth && columns === 3,
      "md:w-[100%]": fullWidth,
    }),
  };
};
