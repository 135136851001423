import Link from "next/link";
import type {
  Author,
  Category,
  FeaturedImage as FeaturedImageType,
} from "@/types";
import { Heading, RichText } from "@/components/atoms";
import { PostIconInfo } from "@/components/molecules";
import CustomMedia from "@/components/molecules/CustomMedia";
import CustomImage from "./CustomImage";
import { MOBILE_BREAK } from "@/constants/utility";
import { useWindowSize } from "@/hooks/useWindowSize";

interface Props {
  author?: Author;
  date?: string;
  modified?: string;
  image?: FeaturedImageType;
  hideFeaturedImage?: {
    value: "Yes" | "No";
  };
  title: string;
  categories?: Category[];
  youtubeUrl?: string;
  youtubeTitle?: string;
  excerpt?: string;
  content?: string;
}

export default function PostHeader({
  title,
  image,
  hideFeaturedImage,
  date,
  modified,
  author,
  categories,
  youtubeUrl,
  youtubeTitle,
  excerpt,
  content,
}: Props) {
  const { width: windowWidth } = useWindowSize();
  const isMobileDevice = windowWidth < MOBILE_BREAK;
  const hideImage = hideFeaturedImage?.value === "Yes" ? true : false;

  const defaultImage = {
    full: "/images/blog-post-feature-image-default.webp",
    alt: "A green and blue background with three blue slanted rectangles in the middle. A white Dashlane logo is overlaid on top.",
  };

  return (
    <div className="w-bleed-full max-w-none bg-blue-1">
      <div className="mx-auto max-w-7xl px-4 py-12 md:px-8 md:py-20">
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
          <div className="flex flex-col gap-6">
            {categories && (
              <div className="flex flex-wrap gap-2" role="group">
                {categories.map((category) => {
                  const { uri, name } = category;
                  return (
                    <Link
                      href={uri}
                      key={name}
                      className="rounded-md border border-blue-3 bg-blue-3 px-2.5 py-[6px] text-xs font-medium uppercase text-blue-8 drop-shadow-none hover:no-underline"
                    >
                      {name}
                    </Link>
                  );
                })}
              </div>
            )}
            {title && (
              <Heading className="text-4xl tracking-[-1px] md:text-5xl">
                {title}
              </Heading>
            )}
            {author && date && (
              <PostIconInfo
                author={author}
                date={date}
                modified={modified}
                content={content}
                isMobile={isMobileDevice}
              />
            )}
            {excerpt && !isMobileDevice && (
              <RichText className="text-lg" tag="p">
                {excerpt.length >= 310
                  ? excerpt.substring(0, 310) + "..."
                  : excerpt}
              </RichText>
            )}
          </div>
          <div>
            {youtubeUrl && (
              <CustomMedia
                media={{
                  media: {
                    url: youtubeUrl,
                    title: youtubeTitle,
                    providerNameSlug: "youtube",
                  },
                  type: "youtube",
                }}
              />
            )}
            {image && !hideImage && !youtubeUrl ? (
              <div>
                <CustomImage
                  full={image?.node?.sourceUrl}
                  alt={image?.node.altText}
                  className="w-full"
                  objectFit="cover"
                  aspect="3:2"
                />
              </div>
            ) : (
              <div>
                <CustomImage
                  full={defaultImage.full}
                  alt={defaultImage.alt}
                  className="h-full w-full"
                  objectFit="cover"
                  aspect="3:2"
                />
              </div>
            )}
            {excerpt && isMobileDevice && (
              <div className="mt-6">
                <RichText className="text-lg" tag="span">
                  {excerpt.length >= 310
                    ? excerpt.substring(0, 310) + "..."
                    : excerpt}
                </RichText>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
