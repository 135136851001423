import React from "react";
import cn from "classnames";
import BlockContent from "../molecules/BlockContent";
import AboveHeading from "../molecules/AboveHeading";
import CustomButtonGroup from "../molecules/CustomButtonGroup";
import { CustomButtonInterface } from "../molecules/CustomButton";
import { RichText } from "@/components/atoms";
import {
  marginBMd,
  textHeadlineNeutral,
  textHeadlineInverse,
  h1Text,
  textBodyInverse,
  backgroundInverse,
  backgroundNeutral,
  h3Text,
  h4Text,
  textBodyNeutral,
  pTextL,
  h1TextLarge,
} from "@/constants/standardCSSClasses";
import { Block } from "@/types";
import { Blocks } from "@/components/molecules";
import { AttributesExtension } from "@/utilities/getBlockContentProps";
import { gql } from "@apollo/client";
import getEditorBlock from "@/fragments/fragmentFunctions/GetEditorBlock";
import CustomHeading, {
  HeadingOptions,
} from "@/components/molecules/CustomHeading";

interface Props extends AttributesExtension {
  includeAboveHeading?: boolean;
  includeContent?: boolean;
  includeButtons?: boolean;
  aboveHeading?: string;
  heading?: string;
  headingSize?: string;
  headingOptions?: HeadingOptions;
  content?: string;
  buttonOne?: CustomButtonInterface;
  buttonTwo?: CustomButtonInterface;
  buttonsNumber?: number;
  variant?: string;
  paddingTop?: string;
  paddingBottom?: string;
  innerBlocks?: Block[];
}

export default function StatisticsCardsBlock({
  includeAboveHeading = false,
  includeContent = false,
  includeButtons = false,
  aboveHeading = "",
  heading = "",
  headingSize = "lg",
  headingOptions = {
    tag: 2,
  },
  content = "",
  buttonsNumber = 0,
  buttonOne,
  buttonTwo,
  variant = "dark",
  paddingTop = "large",
  paddingBottom = "large",
  innerBlocks,
  blockContentProps,
}: Props) {
  const numElements = innerBlocks?.length || 0;

  const cards = innerBlocks?.length ? (
    <Blocks
      blocks={innerBlocks.map((each) => {
        each.attributes.variant = variant;

        return each;
      })}
    />
  ) : null;

  const blockClassNames = cn("relative", {
    [backgroundInverse]: variant === "dark",
    [backgroundNeutral]: variant === "light",
  });

  const headerClassNames = cn("text-center", marginBMd, {
    [textHeadlineInverse]: variant === "dark",
    [textHeadlineNeutral]: variant !== "dark",
    [h1TextLarge]: headingSize === "xl",
    [h1Text]: headingSize === "lg",
    [h3Text]: headingSize === "md",
    [h4Text]: headingSize === "sm",
  });

  const pTextClassNames = cn("max-w-xl mx-auto text-center", pTextL, {
    [textBodyInverse]: variant === "dark",
    [textBodyNeutral]: variant !== "dark",
  });

  const gridColsElementClassName = `md:grid-cols-${numElements}`;

  const elementClassNames = cn(
    "grid gap-6 w-full mt-10 md:mt-16 grid-cols-1",
    gridColsElementClassName
  );

  const buttons = [];

  if (buttonsNumber > 0) {
    buttons.push({
      ...buttonOne,
      variant: variant === "dark" ? "white" : "dark",
    });
  }

  if (buttonsNumber > 1) {
    buttons.push({
      ...buttonTwo,
      variant: variant === "dark" ? "dark" : "light",
    });
  }

  return (
    <BlockContent
      className={blockClassNames}
      variant={variant}
      width="full"
      defaultValue="full"
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      constrain
      {...blockContentProps}
    >
      {includeAboveHeading && (
        <AboveHeading variant={variant} className="text-center">
          {aboveHeading}
        </AboveHeading>
      )}
      <CustomHeading
        heading={heading}
        headingOptions={headingOptions}
        headingClassNames={headerClassNames}
      />
      {includeContent && (
        <RichText className={pTextClassNames} tag="p">
          {content}
        </RichText>
      )}
      <div className={elementClassNames}>{cards}</div>
      {includeButtons && (
        <CustomButtonGroup
          align="center"
          buttons={buttons}
          downloadAndOpenTab={false}
          className="mt-10 md:mt-16"
        />
      )}
    </BlockContent>
  );
}

// Must match __typename
const BLOCK_NAME = "TenupStatisticsCards";

StatisticsCardsBlock.displayName = BLOCK_NAME;

StatisticsCardsBlock.fragments = {
  key: `${BLOCK_NAME}BlockFragment`,
  entry: gql`
    fragment ${BLOCK_NAME}BlockFragment on ${BLOCK_NAME} {
      ${getEditorBlock()}
      attributes {
        ... on ${BLOCK_NAME}Attributes {
          includeAboveHeading
          includeContent
          includeButtons
          aboveHeading
          heading
          headingSize
          headingOptions
          content
          className
          buttonOne
          buttonTwo
          buttonsNumber
          ga4SectionId
          metadata
          variant
          paddingTop
          paddingBottom
        }
      }
    }
  `,
};
