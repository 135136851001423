import { FormEvent, useEffect, useRef } from "react";
import { MarketoFormErrorsInterface } from "@/types";
import {
  getFieldWrapClasses,
  getRequired,
  getFieldClasses,
  getErrorMessages,
} from "@/utilities/marketoForms";
import cn from "classnames";
import { MarketoFormFieldAny } from "@/types";
import { caretDown } from "@/constants/icons";
import { iconFillDark } from "@/constants/standardCSSClasses";

interface SelectFieldInterface {
  field: MarketoFormFieldAny;
  register: Function;
  errors: MarketoFormErrorsInterface;
  watch: Function;
}

export default function SelectField({
  field,
  register,
  errors,
}: SelectFieldInterface) {
  const stateRef = useRef<HTMLDivElement>(null);

  const inputClassNames = cn(
    getFieldClasses(),
    "pl-2.5 pr-8 py-2.5 max-w-full w-full rounded border leading-tight focus:outline-none bg-transparent appearance-none",
    {
      "border-pink-dark focus:border-pink-dark": errors[field.name],
      "border-grey-1 focus:border-blue-8": !errors[field.name],
    }
  );

  useEffect(() => {
    function handleCountryChange(e: any) {
      if (field.name === "State") {
        stateRef.current?.classList.toggle(
          "hidden",
          e.detail !== "United States"
        );
      }
    }

    if (field.name === "State" && stateRef.current) {
      document.addEventListener("countryChange", handleCountryChange);
    }

    return () => {
      if (field.name === "State" && stateRef.current) {
        document.removeEventListener("countryChange", handleCountryChange);
      }
    };
  }, [field.name]);

  return (
    <div
      className={`${getFieldWrapClasses(field)} ${
        field.name === "State" ? "hidden" : ""
      }`}
      ref={field.name === "State" ? stateRef : undefined}
    >
      <label className="block text-sm">
        {field.label}
        {getRequired(field)}
      </label>
      {field.options && (
        <div className="relative w-full">
          <select
            className={inputClassNames}
            id={field.name}
            {...register(field.name, {
              required: field.required,
              onChange: (e: FormEvent) => {
                const newValue = (e.target as HTMLInputElement).value;

                if (field.name === "Country") {
                  document.dispatchEvent(
                    new CustomEvent("countryChange", { detail: newValue })
                  );
                }

                if (field.name !== "PartnershipsType") return;

                if (newValue === "Strategic" || newValue === "Reseller") {
                  window.location.href =
                    "https://partners.dashlane.com/partner/registration";
                } else if (newValue === "Affiliate") {
                  window.location.href =
                    "/partnerships/affiliates#PartnershipsForm";
                } else if (newValue === "Influencers") {
                  window.location.href =
                    "/partnerships/influencers#PartnershipsForm";
                }
              },
            })}
          >
            {field.options.map((option) => {
              return (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              );
            })}
          </select>
          <div
            className={cn(
              iconFillDark,
              "pointer-events-none absolute right-2 top-3 h-5"
            )}
          >
            {caretDown}
          </div>
        </div>
      )}
      {getErrorMessages(field, errors)}
    </div>
  );
}
