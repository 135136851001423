import {
  ColumnIconLink,
  ColumnLink,
  PanelData,
} from "@/components/molecules/MenuPanel";
import { EnhancedMenuItem } from "@/types/Menu";

export const mapNavPanelData = (data: EnhancedMenuItem): PanelData => {
  const response: PanelData = {
    type: "panel",
    columns: [],
    posts: [],
    ctaBar: undefined,
  };

  response.columns =
    data.children
      ?.filter((child) => child.structure === "column")
      .map((column) => {
        const parsedColumn = {
          id: column.id,
          title: column.label,
          ga4Id: column.ga4Id,
          items:
            column.children?.map((childColumn) => {
              const type =
                childColumn.isShowMoreLink === "false" ? "icon" : "link";
              if (type === "link") {
                return {
                  id: childColumn.id,
                  type,
                  url: childColumn.url,
                  label: childColumn.label,
                  ga4Id: childColumn.ga4Id,
                } as ColumnLink;
              }

              return {
                id: childColumn.id,
                type,
                img: childColumn.image,
                variant: childColumn.imageVariant,
                url: childColumn.url,
                title: childColumn.title ?? childColumn.label,
                description: childColumn.description ?? "",
                ga4Id: childColumn.ga4Id,
              } as ColumnIconLink;
            }) ?? [],
        };

        return parsedColumn;
      }) ?? [];

  response.posts =
    data.children
      ?.filter((child) => child.structure === "featured_column")
      .map((featColumn) => {
        const parsedPost = {
          id: featColumn.id,
          title: featColumn.label,
          ga4Id: featColumn.ga4Id,
          items:
            featColumn.children?.map((childFeatColumn) => ({
              id: childFeatColumn.id,
              type: "post" as "post",
              img: childFeatColumn.connectedNode?.node.featuredImage?.node
                ?.sourceUrl,
              url: childFeatColumn.url,
              customImage: childFeatColumn.customImage,
              title: childFeatColumn.title ?? childFeatColumn.label,
              ga4Id: childFeatColumn.ga4Id,
            })) ?? [],
        };

        return parsedPost;
      }) ?? [];

  const ctaBarData = data.children?.find(
    (child) => child.structure === "simple_panel"
  );

  if (ctaBarData) {
    response.ctaBar = {
      id: ctaBarData.id,
      ga4Id: ctaBarData.ga4Id,
      items:
        ctaBarData.children?.map((barItem) => ({
          id: barItem.id,
          icon: barItem.image,
          label: barItem.title || barItem.label,
          url: barItem.url,
          ga4Id: barItem.ga4Id,
        })) ?? [],
    };
  }

  return response;
};

export type MenuLocations = "PRIMARY" | "FOOTER" | "DASHLANE_MEGAMENU";
