import { EnhancedMenuItems, MenuItems } from "@/types/Menu";
import { BannerInterface } from "../molecules/Banner";
import { Translated } from "@/types";
import EnhancedHeader from "./EnhancedHeader";
import Header from "./Header";
import { sanitizedMenuItemsByLocation } from "@/utilities/sanitizedMenuItemsByLocation";
import { SecondaryBannerProps } from "../molecules/SecondaryBanner";

export interface HeaderProps {
  title: string;
  description: string;
  menuItems?: MenuItems;
  enhancedMenuItems?: EnhancedMenuItems;
  banner?: {
    attributes: BannerInterface;
  };
  secondaryBanner?: {
    attributes: SecondaryBannerProps;
  };
  availableLocales?: Translated[];
  variant?: string;
}

export default function DynamicHeader({
  menuItems = [],
  enhancedMenuItems = [],
  ...props
}: HeaderProps) {
  const sanitizedMenuItems = sanitizedMenuItemsByLocation(
    enhancedMenuItems as any,
    "DASHLANE_MEGAMENU"
  );

  return (
    <>
      {sanitizedMenuItems.length ? (
        <EnhancedHeader enhancedMenuItems={sanitizedMenuItems} {...props} />
      ) : (
        <Header menuItems={menuItems} {...props} />
      )}
    </>
  );
}
