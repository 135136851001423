export const T2S_SELECT_DATE = {
  "en": "Select a convenient date and time",
  "fr": "Sélectionnez une date et une heure qui vous conviennent",
  "de": "Wählen Sie ein passendes Datum und eine passende Uhrzeit aus",
  "es": "Seleccione una fecha y hora convenientes",
  "pt-br": "Select a convenient date and time",
  "it": "Select a convenient date and time",
  "ja": "Select a convenient date and time",
  "ko": "Select a convenient date and time",
  "nl": "Select a convenient date and time",
  "pt-pt": "Select a convenient date and time",
  "sv": "Select a convenient date and time",
  "zh": "Select a convenient date and time"
}