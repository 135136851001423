export const T2S_WHAT_HAPPENS_NEXT = {
  "en": "What happens next?",
  "fr": "Que se passe-t-il ensuite ?",
  "de": "Was passiert nun?",
  "es": "¿Qué sucede a continuación?",
  "pt-br": "What happens next?",
  "it": "What happens next?",
  "ja": "What happens next?",
  "ko": "What happens next?",
  "nl": "What happens next?",
  "pt-pt": "What happens next?",
  "sv": "What happens next?",
  "zh": "What happens next?"
}