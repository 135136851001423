export const PRIVACY_CONSENT_TITLE_CTA_REDESIGN = {
  "en": "Manage cookie preferences",
  "fr": "Gérer les préférences en matière de cookies",
  "de": "Cookie-Einstellungen verwalten",
  "es": "Administrar preferencias de cookies",
  "pt-br": "Manage cookie preferences",
  "it": "Manage cookie preferences",
  "ja": "Manage cookie preferences",
  "ko": "Manage cookie preferences",
  "nl": "Manage cookie preferences",
  "pt-pt": "Manage cookie preferences",
  "sv": "Manage cookie preferences",
  "zh": "Manage cookie preferences"
}