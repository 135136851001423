import React, { memo, useState, useRef, useEffect } from "react";
import cn from "classnames";
import { RichText } from "@/components/atoms";
import BlockContent from "../molecules/BlockContent";
import AboveHeading from "../molecules/AboveHeading";
import CustomImage, { CustomImageInterface } from "../molecules/CustomImage";
import ValidationLogo, {
  ValidationLogoInterface,
} from "../molecules/ValidationLogo";
import {
  marginBMd,
  textHeadlineNeutral,
  textHeadlineInverse,
  textBodyInverse,
  textBodyNeutral,
  backgroundInverse,
  backgroundNeutral,
  h1Text,
} from "@/constants/standardCSSClasses";
import BelowButtonsText from "@/components/molecules/BelowButtonsText";
import { Blocks } from "@/components/molecules";
import { Block } from "@/types";
import CustomHeading, {
  HeadingOptions,
} from "@/components/molecules/CustomHeading";
import MarketoFormBuilder from "@/components/molecules/MarketoFormBuilder";
import { MarketoFormFieldAny } from "@/types";
import { useWindowSize } from "@/hooks/useWindowSize";
import { MD_BREAK, MOBILE_BREAK } from "@/constants/utility";
import { CustomButtonInterface } from "@/components/molecules/CustomButton";
import { AttributesExtension } from "@/utilities/getBlockContentProps";
import { gql } from "@apollo/client";
import getEditorBlock from "@/fragments/fragmentFunctions/GetEditorBlock";

interface Props extends AttributesExtension {
  anchor?: string;
  includeAboveHeading?: boolean;
  includeBelowButtons?: boolean;
  includeContent?: boolean;
  hasCustomRedirect?: boolean;
  aboveHeading?: string;
  heading?: string;
  headingOptions?: HeadingOptions;
  content?: string;
  variant?: string;
  width?: string;
  textPosition?: string;
  belowButtons?: string;
  image?: CustomImageInterface;
  contentType: string;
  supportingContent?: string;
  buttonText?: string;
  inputPlaceholder?: string;
  redirectUrl?: string;
  formErrorMessage?: string;
  formId?: number;
  formName?: string;
  innerBlocks?: Block[];
  fields?: MarketoFormFieldAny[];
  starterInfo?: string;
  starterButton?: CustomButtonInterface;
  paddingTop?: string;
  paddingBottom?: string;
  newDesign?: boolean;
  secondVariant?: string;
  imagesText?: string;
  images?: Array<ValidationLogoInterface>;
  formTitle?: string;
  formContent?: string;
}

export default function MarketoFormBlock({
  anchor,
  includeAboveHeading = true,
  includeBelowButtons = true,
  includeContent = true,
  hasCustomRedirect = false,
  aboveHeading = "",
  heading = "",
  headingOptions = {
    tag: 2,
  },
  content = "",
  variant = "white",
  width,
  textPosition = "lc",
  belowButtons = "",
  image = {
    full: "",
    alt: "",
  },
  supportingContent = "features",
  redirectUrl = "",
  formErrorMessage = "",
  formId,
  formName = "",
  fields,
  contentType,
  innerBlocks,
  starterInfo = "",
  starterButton = {
    text: "Click Here",
    variant: "dark",
  },
  newDesign = true,
  secondVariant = "light",
  imagesText = "",
  images = [],
  formTitle = "",
  formContent = "",
  blockContentProps,
  paddingTop,
  paddingBottom,
}: Props) {
  const { width: windowWidth } = useWindowSize();
  const isMobile = windowWidth < MD_BREAK;
  const isMobileDevice = windowWidth < MOBILE_BREAK;
  const formRef = useRef(null); //we will store the height of the form with position absolute
  const blockRef = useRef(null); //we will store the height of the block div
  const [formHeight, setFormHeight] = useState(0);
  const [blockHeight, setBlockHeight] = useState(0);
  const [customPaddingBottom, setCustomPaddingBottom] = useState(0);

  useEffect(() => {
    if (formRef.current && blockRef.current) {
      const formElement = formRef.current as HTMLElement;
      const blockElement = blockRef.current as HTMLElement;

      setFormHeight(formElement.clientHeight);
      setBlockHeight(blockElement.clientHeight);
    }
  }, [formRef, blockRef]);

  useEffect(() => {
    if (formHeight > 0 && blockHeight > 0) {
      setCustomPaddingBottom(Math.abs(formHeight - blockHeight));
    }
  }, [formHeight, blockHeight]);

  const getPaddingValue = (padding: string): number => {
    switch (padding) {
      case "none":
        return 0;
      case "small":
        return 20;
      case "default":
        return 32;
      case "medium":
        return 40;
      case "large":
        return 60;
      default:
        throw new Error("Invalid padding value");
    }
  };

  const features = innerBlocks?.length ? (
    <Blocks
      contentType={contentType}
      blocks={innerBlocks.map((each) => {
        each.attributes.variant = variant;
        each.attributes.hasNewDesign = true;
        return each;
      })}
    />
  ) : null;

  const headerClassNames = cn("mt-0", h1Text, marginBMd, {
    [textHeadlineInverse]: variant === "dark",
    [textHeadlineNeutral]: variant !== "dark",
  });

  const pTextClassNames = cn({
    [textBodyInverse]: variant === "dark",
    [textBodyNeutral]: variant !== "dark",
    "mb-8": includeBelowButtons || supportingContent,
    "mb-0": !includeBelowButtons && !supportingContent,
    [textHeadlineInverse]: newDesign && variant === "dark",
  });

  const formClassNames = cn("mt-8 lg:mt-0 mb-8 lg:mb-0 rounded", {
    "px-4 py-6 lg:px-6 lg:py-8 md:px-12 md:py-10 bg-white":
      width !== "full" && formName !== "sales" && !newDesign,
    "px-4 py-6 lg:px-6 lg:py-8 md:px-12 md:py-10 lg:px-0 lg:py-0 bg-white lg:bg-none":
      width === "full" &&
      variant === "light" &&
      formName !== "sales" &&
      !newDesign,
    "bg-white": formName === "sales",
    "px-8 py-8 !mt-0 !mb-0": newDesign,
  });

  const innerClasses = cn("lg:grid relative", {
    "lg:gap-40 ": width === "full" && textPosition !== "above",
    "lg:gap-20": width !== "full" && textPosition !== "above",
    "lg:gap-0": textPosition === "above",
    "lg:grid-cols-2": textPosition !== "above",
    "max-w-lg mx-auto": textPosition === "above",
  });

  const textClasses = cn({
    "self-top": textPosition === "lt",
    "self-center": textPosition === "lc",
    "text-center": textPosition === "above",
  });

  const blockClassNames = cn("w-bleed-full max-w-none", {
    [backgroundInverse]: variant === "dark",
    [backgroundNeutral]: variant === "light",
    "bg-white": variant === "white",
  });

  const secondBlockClassNames = cn("w-bleed-full max-w-none", {
    [backgroundInverse]: secondVariant === "dark",
    [backgroundNeutral]: secondVariant === "light",
    "bg-white": secondVariant === "white",
  });

  const paddingTopClassNames = cn({
    "pt-8": paddingTop === "default",
    "pt-0": paddingTop === "none",
    "pt-5": paddingTop === "small",
    "pt-10": paddingTop === "medium",
    "pt-[60px]": paddingTop === "large",
  });

  const newDesignHeaderClassNames = cn("mt-0 text-5xl", marginBMd, {
    [textHeadlineInverse]: variant === "dark",
    [textHeadlineNeutral]: variant !== "dark",
  });

  const gridClassNames = cn(
    "grid grid-cols-2 md:flex md:flex-wrap justify-center gap-x-8 gap-y-3"
  );

  const extraPadding = getPaddingValue(paddingBottom as string) * 2;
  let paddingBottomCustom = getPaddingValue(paddingBottom as string);

  if (!isMobileDevice) {
    if (customPaddingBottom < 140) {
      paddingBottomCustom = 140 + extraPadding;
    } else {
      paddingBottomCustom = customPaddingBottom + extraPadding;
    }
  }

  const form = formId &&
    fields &&
    (!!fields.length || formName === "embed") && (
      <div className="mx-auto md:mx-0 md:max-w-none">
        <MemoizedForm
          fields={fields}
          formId={formId}
          embed={formName === "embed"}
          variant={variant}
          settings={{
            hasCustomRedirect,
            redirectUrl,
            formErrorMessage,
            starterInfo,
            starterButton,
            formTitle,
            formContent,
          }}
        />
      </div>
    );

  const mobileContent = (
    <>
      {supportingContent === "image" && image.full && (
        <CustomImage alt={image.alt} full={image.full} aspect="2:1" />
      )}
      {supportingContent === "features" && features}
      {includeBelowButtons && belowButtons && (
        <BelowButtonsText
          variant={variant}
          centered={textPosition === "above"}
          mobileAlign={textPosition === "above" ? "center" : "left"}
        >
          {belowButtons}
        </BelowButtonsText>
      )}
    </>
  );

  return (
    <>
      {!newDesign && (
        <BlockContent
          id={anchor}
          className="relative !mb-0 !mt-0"
          contentType={contentType}
          variant={
            width === "full" && !isMobile && textPosition !== "above"
              ? "white"
              : variant
          }
          width={isMobile ? "full" : width}
          defaultValue="box"
          constrain
          paddingTop={paddingTop}
          paddingBottom={paddingBottom}
          {...blockContentProps}
        >
          {width === "full" &&
            variant === "light" &&
            textPosition !== "above" && (
              <div
                className={cn(
                  backgroundNeutral,
                  "absolute left-0 top-0 z-0 hidden h-full w-1/2 lg:block"
                )}
              />
            )}
          <div className={innerClasses}>
            <div className={textClasses}>
              {includeAboveHeading && aboveHeading && (
                <AboveHeading variant={variant}>{aboveHeading}</AboveHeading>
              )}
              {heading && (
                <CustomHeading
                  heading={heading}
                  headingOptions={headingOptions}
                  headingClassNames={headerClassNames}
                />
              )}
              {includeContent && content && (
                <RichText className={pTextClassNames} tag="p">
                  {content}
                </RichText>
              )}
              {!isMobile && mobileContent}
            </div>
            <div className="self-top">
              <div className={formClassNames}>{form}</div>
              {isMobile && mobileContent}
            </div>
          </div>
        </BlockContent>
      )}
      {newDesign && (
        <BlockContent
          id={anchor}
          contentType={contentType}
          width={"full"}
          defaultValue="full"
          noSpacing={true}
          {...blockContentProps}
        >
          <div ref={blockRef} className="relative">
            <div className={cn(blockClassNames, paddingTopClassNames)}>
              <div className="relative mx-auto max-w-7xl px-4 pb-12 md:px-8 md:pb-0">
                <div className="grid grid-cols-1 items-center md:grid-cols-2">
                  <div className="left max-w-[600px] gap-8 py-0 md:gap-6 md:py-12">
                    <div className={textClasses}>
                      {includeAboveHeading && aboveHeading && (
                        <AboveHeading variant={variant}>
                          {aboveHeading}
                        </AboveHeading>
                      )}
                      {heading && (
                        <CustomHeading
                          heading={heading}
                          headingOptions={headingOptions}
                          headingClassNames={newDesignHeaderClassNames}
                        />
                      )}
                      {includeContent && content && (
                        <RichText className={pTextClassNames} tag="p">
                          {content}
                        </RichText>
                      )}
                      {mobileContent}
                    </div>
                  </div>
                  <div
                    ref={formRef}
                    className="relative top-0 z-10 w-auto max-w-none rounded bg-white md:absolute md:right-[2.5%] md:w-[45%] md:max-w-[600px]"
                  >
                    <div className="self-top">
                      <div className={formClassNames}>{form}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={cn(secondBlockClassNames)}
              style={{ paddingBottom: `${paddingBottomCustom}px` }}
            >
              <div className="mx-auto max-w-7xl px-4 md:px-8">
                <div className="grid grid-cols-1 items-center md:grid-cols-2">
                  <div className="left md:max-w-[600px]">
                    <AboveHeading
                      className={cn("pt-12 text-center")}
                      variant={secondVariant}
                    >
                      {imagesText}
                    </AboveHeading>
                    <div className="flex flex-wrap justify-center">
                      <div className={gridClassNames}>
                        {images.map((each, index) => (
                          <ValidationLogo
                            key={index}
                            variant={secondVariant}
                            {...each}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="right-0" />
                </div>
              </div>
            </div>
          </div>
        </BlockContent>
      )}
    </>
  );
}

interface MemoizedFormProps {
  fields: MarketoFormFieldAny[];
  formId: number;
  embed: boolean;
  variant?: string;
  settings: {
    hasCustomRedirect: boolean;
    redirectUrl: string;
    formErrorMessage: string;
    starterInfo?: string;
    starterButton?: CustomButtonInterface;
    formTitle?: string;
    formContent?: string;
  };
}

const MemoizedForm = memo(
  ({ fields, formId, settings, embed }: MemoizedFormProps) => {
    return (
      <MarketoFormBuilder
        fields={fields}
        formId={formId}
        settings={settings}
        embed={embed}
      />
    );
  }
);

// Must match __typename
const BLOCK_NAME = "TenupMarketoForm";

MarketoFormBlock.displayName = BLOCK_NAME;

MarketoFormBlock.fragments = {
  key: `${BLOCK_NAME}BlockFragment`,
  entry: gql`
    fragment ${BLOCK_NAME}BlockFragment on ${BLOCK_NAME} {
      ${getEditorBlock()}
      attributes {
        ... on ${BLOCK_NAME}Attributes {
          aboveHeading
          anchor
          belowButtons
          className
          content
          fields
          formErrorMessage
          formId
          formName
          hasCustomRedirect
          heading
          headingOptions
          image
          includeAboveHeading
          includeBelowButtons
          includeContent
          metadata
          redirectUrl
          starterButton
          starterInfo
          supportingContent
          textPosition
          variant
          width
          paddingTop
          paddingBottom
          newDesign
          secondVariant
          imagesText
          images
          formTitle
          formContent
        }
      }
    }
  `,
};
