import Link from "next/link";

import { RichText } from "@/components/atoms";
import { ReadMore } from "@/components/molecules";
import { Author } from "@/types";

interface Props {
  author: Author;
}

export default function PostAuthor({ author }: Props) {
  const { description, name, uri } = author.node;

  return (
    <div className="my-[60px]">
      <div className="flex flex-col rounded-2xl border-2 border-solid border-slate-100 bg-blue-7 bg-testimonials-pattern-mobile bg-contain bg-right bg-no-repeat px-[60px] py-[60px] md:flex-row md:bg-testimonials-pattern md:px-20  md:text-left">
        <div className="flex flex-col">
          <Link
            className="my-1 text-3xl font-medium text-white hover:no-underline"
            href={uri}
          >
            {name}
            {description && (
              <RichText tag="p" className="mt-4 text-xl font-normal">
                {description}
              </RichText>
            )}
          </Link>
          <div>
            <ReadMore
              className="mt-4 fill-white text-lg/[24px] font-medium normal-case text-white no-underline hover:text-white hover:no-underline"
              href={uri}
              arrow={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
