export const T2S_EMAIL_US = {
  "en": "Confirm your email",
  "fr": "Confirmez votre adresse e-mail",
  "de": "Bestätigen Sie bitte Ihre E-Mail-Adresse",
  "es": "Confirme su correo electrónico",
  "pt-br": "Confirm your email",
  "it": "Confirm your email",
  "ja": "Confirm your email",
  "ko": "Confirm your email",
  "nl": "Confirm your email",
  "pt-pt": "Confirm your email",
  "sv": "Confirm your email",
  "zh": "Confirm your email"
}