export const BLOG_CASE_STUDY_INDUSTRY = {
  "en": "Industry",
  "fr": "Secteur",
  "de": "Branche",
  "es": "Sector",
  "pt-br": "Industry",
  "it": "Industry",
  "ja": "Industry",
  "ko": "Industry",
  "nl": "Industry",
  "pt-pt": "Industry",
  "sv": "Industry",
  "zh": "Industry"
}