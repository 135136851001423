import React from "react";

import { useRouter } from "next/router";
import type { PostPreview } from "@/types";
// import { useTranslate } from "@/hooks/useTranslate";
// import { POST_PREVIEW_SECTION_SEE_MORE } from "@/i18n/translations/POST_PREVIEW_SECTION_SEE_MORE";
import { formatEventDate } from "@/utilities/formatEventDate";
import ContentCards from "@/components/molecules/ContentCards";
import { useBrowserLanguage } from "@/hooks/useBrowserLanguage";
import { AttributesExtension } from "@/utilities/getBlockContentProps";
import { HeadingOptions } from "./CustomHeading";
import { CustomButtonInterface } from "./CustomButton";

interface Props extends AttributesExtension {
  posts: PostPreview[];
  variant: string;
  includeTag: boolean;
  heading?: string;
  headingOptions?: HeadingOptions;
  aboveHeading?: string;
  button?: CustomButtonInterface;
  content?: string;
  contentType: string;
  columns?: number;
  paddingTop?: string;
  paddingBottom?: string;
}

export default function PostsContentCards({
  posts,
  variant = "dark",
  includeTag = true,
  aboveHeading = "",
  heading,
  headingOptions = {
    tag: 2,
  },
  button,
  content,
  contentType,
  columns = 3,
  blockContentProps,
  paddingTop = "large",
  paddingBottom = "large",
}: Props) {
  const { locale = "en" } = useRouter();
  const { language } = useBrowserLanguage(locale);

  const cards: Array<any> = posts
    ? posts.map((each: any) => {
        return {
          variant,
          imageAspect: "contentCard",
          visual: "image",
          border: false,
          heading: each.title,
          icon: false,
          tag: includeTag && {
            link: each.categories?.nodes[0]?.uri,
            name: each.categories?.nodes[0]?.name,
          },
          image: {
            full: each.featuredImage?.node?.sourceUrl
              ? each.featuredImage.node.sourceUrl
              : "/images/blog-post-default.webp",
            alt: each.featuredImage?.node?.altText
              ? each.featuredImage.node.altText
              : "A green and blue background with three blue slanted rectangles in the middle. A white Dashlane logo is overlaid on top.",
          },
          includeCardButtons: false,
          linkUri: each.uri,
          linkCard: true,
          eventDate: each.resourceFields?.eventDate
            ? formatEventDate(each.resourceFields.eventDate, language, true)
            : "",
        };
      })
    : [];

  return (
    <ContentCards
      includeAboveHeading={false}
      includeButtons={false}
      includeContent={true}
      aboveHeading={aboveHeading}
      heading={heading}
      headingOptions={headingOptions}
      content={content}
      variant={variant}
      button={button}
      contentType={contentType}
      cards={cards}
      columns={columns}
      blockContentProps={blockContentProps}
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
    />
  );
}
