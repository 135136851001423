export const T2S_CALL_US = {
  "en": "We'll confirm and reach out to you",
  "fr": "Nous confirmerons et vous contacterons",
  "de": "Wir werden dies bestätigen und uns bei Ihnen melden",
  "es": "Le confirmaremos y nos pondremos en contacto con usted",
  "pt-br": "We'll confirm and reach out to you",
  "it": "We'll confirm and reach out to you",
  "ja": "We'll confirm and reach out to you",
  "ko": "We'll confirm and reach out to you",
  "nl": "We'll confirm and reach out to you",
  "pt-pt": "We'll confirm and reach out to you",
  "sv": "We'll confirm and reach out to you",
  "zh": "We'll confirm and reach out to you"
}