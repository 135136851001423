import { gql } from "@apollo/client";

import * as MENUS from "@/constants/menus";
import {
  BlogInfoFragment,
  FeaturedImageFragment,
  MenuItemFragment,
} from "@/fragments";
import { Container, ContentWrapper, Main } from "@/components/atoms";
import { Blocks, PreviewLoader, SEO } from "@/components/molecules";
import { Footer } from "@/components/organisms";
import type { PageData } from "@/types";
import JumpLinkSubnav from "@/components/molecules/JumpLinkSubnav";
import { getPostDataFromProps } from "@/utilities/getPostDataFromProps";
import { formatLocaleForGraphQL } from "@/utilities/formatLocaleForGraphQL";
import NewMenuItemFragment from "@/fragments/NewMenuItem";
import DynamicHeader from "@/components/organisms/DynamicHeader";
import { Locale } from "@/types";
import flatListToHierarchical from "@/utilities/flatListToHierarchical";
import {
  GetEditorBlocksFragments,
  GetEditorBlocksKeys,
} from "@/fragments/fragmentFunctions";

export default function Component(props: PageData) {
  // Needed for previews to load on the client
  if (props.loading) {
    return <PreviewLoader />;
  }

  const {
    translated,
    siteTitle,
    siteDescription,
    primaryMenu,
    newMenuItems,
    footerMenu,
    content,
    seo,
    editorBlocks,
    contentType,
    jumpLinks,
    banner,
    secondaryBanner,
    uri,
    isFrontPage,
  } = getPostDataFromProps(props, "page");

  return (
    <>
      <SEO
        fullHead={seo.fullHead}
        availableLocales={translated}
        locale={props.locale}
        uri={uri}
        isFrontPage={isFrontPage}
      />
      <DynamicHeader
        title={siteTitle}
        description={siteDescription}
        menuItems={primaryMenu}
        enhancedMenuItems={newMenuItems}
        availableLocales={translated}
        banner={banner?.[0]}
        secondaryBanner={secondaryBanner?.[0]}
      />
      <Main>
        {jumpLinks.length ? (
          <JumpLinkSubnav
            jumpLinks={jumpLinks}
            excerpt={props.data.page.excerpt}
            title={props.data.page.title}
          />
        ) : null}
        <Container tagName="article">
          {editorBlocks.length > 0 ? (
            <Blocks
              blocks={flatListToHierarchical(editorBlocks, {
                idKey: "id",
                parentKey: "parentClientId",
                childrenKey: "innerBlocks",
              })}
              contentType={contentType}
            />
          ) : (
            <ContentWrapper content={content} />
          )}
        </Container>
      </Main>
      <Footer
        title={siteTitle}
        menuItems={footerMenu}
        availableLocales={translated}
        locale={props.locale}
      />
    </>
  );
}

Component.query = gql`
  ${BlogInfoFragment}
  ${FeaturedImageFragment}
  ${MenuItemFragment}
  ${NewMenuItemFragment}
  ${GetEditorBlocksFragments()}
  query GetPageData(
    $databaseId: ID!
    $headerLocation: MenuLocationEnum
    $footerLocation: MenuLocationEnum
    $newMenuLocation: MenuLocationEnum
    $asPreview: Boolean = false
    $locale: LanguageCodeFilterEnum = EN
  ) {
    page(id: $databaseId, idType: DATABASE_ID, asPreview: $asPreview) {
      title
      ${GetEditorBlocksKeys()}
      excerpt
      seo {
        fullHead
      }
      uri
      isFrontPage
      contentType {
        node {
          name
        }
      }
      ...FeaturedImageFragment
    }
    generalSettings {
      ...BlogInfoFragment
    }
    headerMenuItems: menuItems(
      where: { location: $headerLocation, language: $locale }
      first: ${MENUS.NUMBER_OF_MENU_ITEMS}
    ) {
      nodes {
        ...MenuItemFragment
      }
    }
    footerMenuItems: menuItems(
      where: { location: $footerLocation, language: $locale }
      first: ${MENUS.NUMBER_OF_MENU_ITEMS}
    ) {
      nodes {
        ...MenuItemFragment
      }
    }
    newMenuItems: menuItems(
      where: { location: $newMenuLocation, language: $locale }
      first: ${MENUS.NUMBER_OF_MENU_ITEMS}
    ) {
      nodes {
        ...NewMenuItemFragment
      }
    }
  }
`;

Component.variables = (
  { databaseId }: { databaseId: string },
  { asPreview, locale }: { asPreview?: boolean; locale: Locale }
) => {
  return {
    asPreview,
    footerLocation: MENUS.newFooterLocation(locale),
    headerLocation: MENUS.newHeaderLocation(locale),
    locale: formatLocaleForGraphQL(locale),
    newMenuLocation: MENUS.newMenuLocation(locale),
    databaseId,
  };
};
