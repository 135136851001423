import {
  AccoladeBlock,
  AccoladesBlock,
  AccoladesGridBlock,
  AccoladesGridItemBlock,
  BannerBlock,
  ButtonBlock,
  ButtonsBlock,
  CodeBlock,
  CorePreformattedBlock,
  ColumnBlock,
  ColumnsBlock,
  ContentCardBlock,
  ContentCardsBlock,
  ContentCardsManualBlock,
  CookiePreferenceBlock,
  CookiePreferencesBlock,
  CoverBlock,
  CTABlock,
  EmailCaptureBlock,
  EmailCaptureItemBlock,
  EmbedBlock,
  FAQBlock,
  FAQItemBlock,
  FeaturesInteractiveBlock,
  FeaturesInteractiveItemBlock,
  FeaturesStaticBlock,
  GalleryBlock,
  GenericTwoColumnNarrativeBlock,
  GroupBlock,
  HeadingBlock,
  HeroBlock,
  HeroRTBBlock,
  HeroRTBSectionBlock,
  HomepageHeroBlock,
  IconCardsBlock,
  IconFeatureBlock,
  IconFeatureRTBBlock,
  ImageBlock,
  ImageCardAddonBlock,
  ImageCardsAddonBlock,
  ImageCardsBlock,
  InnovationBlock,
  InnovationsBlock,
  JumpLinkStartBlock,
  ListBlock,
  ListItemBlock,
  MarketoFormBlock,
  MarketoFormOverlayBlock,
  MediaTextBlock,
  NewsFeedArticleBlock,
  NewsFeedBlock,
  OfferOverlayBlock,
  ParagraphBlock,
  PartnershipFormBlock,
  PasswordGeneratorBlock,
  PricingBlock,
  PricingColumnBlock,
  PricingItemBlock,
  PullQuoteBlock,
  QuoteBlock,
  RatingCardBlock,
  RatingCardsBlock,
  SeparatorBlock,
  ShortCodeBlock,
  SpacerBlock,
  TableBlock,
  TabularContentBlock,
  TabularContentIconCellBlock,
  TabularContentImageCellBlock,
  TabularContentPricingCellBlock,
  TabularContentRowBlock,
  TabularContentTextCellBlock,
  TeamBlock,
  TeamMemberBlock,
  TestimonialBlock,
  TestimonialsBlock,
  UsernameGeneratorBlock,
  ValidationBlock,
  VideoBlock,
  SecondaryBannerBlock,
  CoreHtmlBlock,
  CareersBlock,
  StatisticsCardsBlock,
  StatisticElementBlock,
  CaseStudyBlock,
} from "@/components/blocks";

export default {
  TenupAccolade: AccoladeBlock,
  TenupAccolades: AccoladesBlock,
  TenupAccoladesGrid: AccoladesGridBlock,
  TenupAccoladesGridItem: AccoladesGridItemBlock,
  TenupBanner: BannerBlock,
  CoreButton: ButtonBlock,
  CoreButtons: ButtonsBlock,
  CoreCode: CodeBlock,
  CorePreformatted: CorePreformattedBlock,
  CoreColumn: ColumnBlock,
  CoreColumns: ColumnsBlock,
  TenupContentCard: ContentCardBlock,
  TenupContentCards: ContentCardsBlock,
  TenupContentCardsManual: ContentCardsManualBlock,
  TenupCookiePreference: CookiePreferenceBlock,
  TenupCookiePreferences: CookiePreferencesBlock,
  CoreCover: CoverBlock,
  TenupCta: CTABlock,
  TenupCareers: CareersBlock,
  TenupEmailCapture: EmailCaptureBlock,
  TenupEmailCaptureItem: EmailCaptureItemBlock,
  CoreEmbed: EmbedBlock,
  TenupFaq: FAQBlock,
  TenupFaqItem: FAQItemBlock,
  TenupFeaturesInteractive: FeaturesInteractiveBlock,
  TenupFeaturesInteractiveItem: FeaturesInteractiveItemBlock,
  TenupFeaturesStatic: FeaturesStaticBlock,
  CoreGallery: GalleryBlock,
  TenupTwoColumnNarrative: GenericTwoColumnNarrativeBlock,
  CoreGroup: GroupBlock,
  CoreHeading: HeadingBlock,
  TenupHero: HeroBlock,
  TenupHeroRtb: HeroRTBBlock,
  TenupHeroRtbSection: HeroRTBSectionBlock,
  TenupHomepageHero: HomepageHeroBlock,
  TenupIconCards: IconCardsBlock,
  TenupIconFeature: IconFeatureBlock,
  TenupIconFeatureRtb: IconFeatureRTBBlock,
  CoreImage: ImageBlock,
  TenupImageCardAddon: ImageCardAddonBlock,
  TenupImageCardsAddon: ImageCardsAddonBlock,
  TenupImageCards: ImageCardsBlock,
  TenupInnovation: InnovationsBlock,
  TenupInnovationSlide: InnovationBlock,
  TenupJumpLinkSection: JumpLinkStartBlock,
  CoreList: ListBlock,
  CoreListItem: ListItemBlock,
  TenupMarketoForm: MarketoFormBlock,
  TenupMarketoFormOverlay: MarketoFormOverlayBlock,
  CoreMediaText: MediaTextBlock,
  TenupNewsFeed: NewsFeedBlock,
  TenupNewsFeedArticle: NewsFeedArticleBlock,
  TenupOfferOverlay: OfferOverlayBlock,
  CoreParagraph: ParagraphBlock,
  TenupPartnershipForm: PartnershipFormBlock,
  TenupPasswordGenerator: PasswordGeneratorBlock,
  TenupPricing: PricingBlock,
  TenupPricingColumn: PricingColumnBlock,
  TenupPricingItem: PricingItemBlock,
  CoreQuote: QuoteBlock,
  CorePullquote: PullQuoteBlock,
  TenupRatingCard: RatingCardBlock,
  TenupRatingCards: RatingCardsBlock,
  CoreSeparator: SeparatorBlock,
  CoreShortcode: ShortCodeBlock,
  CoreSpacer: SpacerBlock,
  CoreTable: TableBlock,
  TenupTabularContent: TabularContentBlock,
  TenupTabularContentRow: TabularContentRowBlock,
  TenupTabularContentTextCell: TabularContentTextCellBlock,
  TenupTabularContentIconCell: TabularContentIconCellBlock,
  TenupTabularContentImageCell: TabularContentImageCellBlock,
  TenupTabularContentPricingCell: TabularContentPricingCellBlock,
  TenupTeam: TeamBlock,
  TenupTeamMember: TeamMemberBlock,
  TenupTestimonial: TestimonialBlock,
  TenupTestimonials: TestimonialsBlock,
  TenupUsernameGenerator: UsernameGeneratorBlock,
  TenupValidation: ValidationBlock,
  CoreVideo: VideoBlock,
  TenupSecondaryBanner: SecondaryBannerBlock,
  CoreHtml: CoreHtmlBlock,
  TenupStatisticsCards: StatisticsCardsBlock,
  TenupStatisticElement: StatisticElementBlock,
  TenupCaseStudy: CaseStudyBlock,
};
