const handleGoogleDataLayer = (Cookies: any) => {
  const userFunnelCookie = Cookies.getJSON("userFunnelCookie") || {};
  const trackingId = userFunnelCookie.trackingId || "";
  const lastClickPreappSource = userFunnelCookie.last_click_preapp_source || "";
  // @TODO pageConfig identifier, language, and tag
  const { identifier, language, tag, country } = window.pageConfig;
  const pageViewArgs = {
    page_lang: language,
    page_type: tag,
    page_name: identifier,
    user_country: country,
  };
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(
    {
      trackingId,
      lastClickPreappSource,
    },
    pageViewArgs
  );
};

interface GaGenerateLeadProps {
  formName: string;
  planType?: string;
}

interface GaEventHandlerProps {
  event: string;
  formName: string;
}

enum GAEvents {
  ADDRESS_COMPLETED = "Address_completed",
  EMAIL_SUBMITTED = "Email_submitted",
  EMAIL_CONFIRMED = "Email_confirmed",
  EMAIL_EXISTS = "Email_exists",
  PLAN_SELECTED = "Plan_selected",
  PURCHASE = "purchase",
  SEATS_SELECTED = "Seats_selected",
}

export const gaGenerateLead = ({ formName, planType }: GaGenerateLeadProps) => {
  window.dataLayer.push({
    event: "generate_lead",
    form_name: formName,
    plan_type: planType,
  });
};

export const gaEventHandler = ({ event, formName }: GaEventHandlerProps) => {
  setTimeout(() => {
    if (window.dataLayer && Array.isArray(window.dataLayer)) {
      window.dataLayer.push({
        event: event,
        form_name: formName,
      });
    }
  }, 1000);
};

export function gaEmailSubmitted() {
  if (window.gtag) {
    window.gtag("event", GAEvents.EMAIL_SUBMITTED);
  }
}

export function gaEmailConfirmed() {
  if (window.gtag) {
    window.gtag("event", GAEvents.EMAIL_CONFIRMED);
  }
}

export function gaEmailExists() {
  if (window.gtag) {
    window.gtag("event", GAEvents.EMAIL_EXISTS);
  }
}

export function gaAddressCompleted(address: {
  country: string;
  postalCode: string;
}) {
  if (window.gtag) {
    window.gtag("event", GAEvents.ADDRESS_COMPLETED, { address });
  }
}

export function gaPlanSelected(plan: string) {
  if (window.gtag) {
    window.gtag("event", GAEvents.PLAN_SELECTED, { plan });
  }
}

export function gaSeatsSelected(seats: number) {
  if (window.gtag) {
    window.gtag("event", GAEvents.SEATS_SELECTED, { seats });
  }
}

export function gaTrialFormSubmitSuccess() {
  gaEventHandler({ event: "trial_form_submit_success", formName: "trial" });
}

export function gaContactSalesFormSubmitSuccess() {
  gaEventHandler({
    event: "contact_sales_form_submit_success",
    formName: "contact_sales",
  });
}

export function gaRequestDemoFormSubmitSuccess() {
  gaEventHandler({
    event: "request_demo_form_submit_success",
    formName: "request_demo",
  });
}

export default handleGoogleDataLayer;
