export const BLOG_CASE_STUDY_COMPANY = {
  "en": "The Company",
  "fr": "Entreprise",
  "de": "Unternehmen",
  "es": "Compañía",
  "pt-br": "The Company",
  "it": "The Company",
  "ja": "The Company",
  "ko": "The Company",
  "nl": "The Company",
  "pt-pt": "The Company",
  "sv": "The Company",
  "zh": "The Company"
}