import React, { useContext } from "react";
import cn from "classnames";

import { RichText } from "@/components/atoms";
import TypeNameContext from "@/contexts/typeNameContext";
import { Style } from "@/types";
import getBlockStyles from "@/utilities/getBlockStyles";
import { gql } from "@apollo/client";
import getEditorBlock from "@/fragments/fragmentFunctions/GetEditorBlock";

interface Props {
  align?: "full" | "wide";
  anchor?: string;
  className?: string;
  content?: string;
  level?: number;
  style?: Style;
  textAlign?: "left" | "center" | "right";
  textColor?: any;
  isMainBlock?: boolean;
}

export default function HeadingBlock({
  align,
  anchor,
  className,
  content,
  level,
  style,
  textAlign,
  textColor,
  isMainBlock = false,
}: Props) {
  const typeNameContext = useContext(TypeNameContext);

  // Correct level 1 to be level 2 to maintain heading hierarchy
  // EntryHeader title will be level 1 on our Posts only
  const correctedHeading = `h${level === 1 ? 2 : level}`;
  const tag = typeNameContext === "Post" ? correctedHeading : `h${level}`;
  const headingStyles = getBlockStyles({ style });

  return (
    <div
      id={anchor}
      className={cn(
        "mb-2 mt-4",
        align === "wide" && "w-full",
        align === "full" && "flex min-h-[20vh] w-full items-center p-8",
        !textAlign && "self-start",
        textAlign === "center" && "self-center",
        textAlign === "right" && "self-end",
        !className?.includes("max-w-") && "max-w-7xl",
        className
      )}
    >
      <RichText
        id={
          tag === "h2" && isMainBlock
            ? content?.replace(/ /g, "-").toLowerCase()
            : undefined
        }
        tag={tag}
        className={cn(
          tag === "h1" && "text-5xl",
          tag === "h2" && "text-4xl",
          tag === "h3" && "text-3xl",
          tag === "h4" && "text-2xl",
          tag === "h5" && "text-xl",
          tag === "h6" && "text-lg",
          textAlign === "center" && "text-center",
          textAlign === "right" && "text-right",
          textColor === "white" && "text-white"
        )}
        style={headingStyles}
      >
        {content}
      </RichText>
    </div>
  );
}

// Must match __typename
const BLOCK_NAME = "CoreHeading";

HeadingBlock.displayName = BLOCK_NAME;

HeadingBlock.fragments = {
  key: `${BLOCK_NAME}BlockFragment`,
  entry: gql`
    fragment ${BLOCK_NAME}BlockFragment on ${BLOCK_NAME} {
      ${getEditorBlock()}
      attributes {
        ... on ${BLOCK_NAME}Attributes {
          align
          anchor
          backgroundColor
          className
          content
          level
          metadata
          style
          textAlign
          textColor
        }
      }
    }
  `,
};
