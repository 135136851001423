import CustomButtonGroup from "@/components/molecules/CustomButtonGroup";

interface Props {
  heading?: string;
  content?: string;
  buttonText?: string;
  buttonLink?: string;
  ga4Id?: string;
  ga4SectionId?: string;
}

export default function PostSidebarCta({
  heading,
  content,
  buttonText,
  buttonLink,
  ga4Id,
  ga4SectionId,
}: Props) {
  const formHeading = heading ? heading : "";
  const formContent = content ? content : "";
  const formButtonText = buttonText ? buttonText : "";

  const buttons = [
    {
      text: formButtonText,
      variant: "white",
      href: buttonLink,
      align: "wide",
      ga4Id,
    },
  ];

  return (
    <div
      className="mt-8 rounded bg-blue-8 px-6 py-10"
      {...(ga4SectionId && { "data-component": ga4SectionId })}
    >
      {heading && <p className="mb-4 text-2xl text-white">{formHeading}</p>}
      {content && <p className="mb-4 text-base text-grey-3">{formContent}</p>}
      {buttonText && buttonLink && (
        <CustomButtonGroup
          align="wide"
          buttons={buttons}
          marginTop=""
          mobileWide={false}
        />
      )}
    </div>
  );
}
