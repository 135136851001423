import { MarketoFormErrorsInterface } from "@/types";
import TextField from "@/components/atoms/form-fields/TextField";
import TextareaField from "@/components/atoms/form-fields/TextareaField";
import SubmitField from "@/components/atoms/form-fields/SubmitField";
import SelectField from "@/components/atoms/form-fields/SelectField";
import CheckboxField from "@/components/atoms/form-fields/CheckboxField";
import FooterField from "@/components/atoms/form-fields/FooterField";
import HiddenField from "@/components/atoms/form-fields/HiddenField";
import { MarketoFormFieldAny } from "@/types";
import Heading from "../Heading";

interface FormFieldInterface {
  field: MarketoFormFieldAny;
  register: Function;
  errors: MarketoFormErrorsInterface;
  isSubmitting: boolean;
  watch: Function;
  formIsValid: boolean;
  hasNewDesign?: boolean;
}

export default function FormField({
  field,
  register,
  errors,
  isSubmitting,
  watch,
  formIsValid,
  hasNewDesign = false,
}: FormFieldInterface) {
  switch (field.type) {
    case "text":
      return (
        <TextField
          field={field}
          register={register}
          errors={errors}
          watch={watch}
        />
      );
    case "phone":
      return (
        <TextField
          field={field}
          register={register}
          errors={errors}
          watch={watch}
        />
      );
    case "email":
      return (
        <TextField
          field={field}
          register={register}
          errors={errors}
          watch={watch}
        />
      );
    case "textarea":
      return (
        <TextareaField
          field={field}
          register={register}
          errors={errors}
          watch={watch}
        />
      );
    case "select":
      return (
        <SelectField
          field={field}
          register={register}
          errors={errors}
          watch={watch}
        />
      );
    case "checkbox":
      return (
        <CheckboxField field={field} register={register} errors={errors} />
      );
    case "hidden":
      return <HiddenField field={field} register={register} />;
    case "submit":
      return (
        <SubmitField
          field={field}
          isSubmitting={isSubmitting}
          formIsValid={formIsValid}
          hasNewDesign={hasNewDesign}
        />
      );
    case "footer":
      return <FooterField field={field} />;
    case "number":
      return (
        <TextField
          field={field}
          register={register}
          errors={errors}
          watch={watch}
        />
      );
    case "date":
      return (
        <TextField
          field={field}
          register={register}
          errors={errors}
          watch={watch}
        />
      );
    case "header":
      return (
        <Heading className="col-span-2 mt-8 w-full text-3xl font-bold">
          {field.label}
        </Heading>
      );
    default:
      return null;
  }
}
